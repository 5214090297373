import { createSlice } from "@reduxjs/toolkit";
import { revertRoot } from "../actions/rootaction";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
let initialState = {
  orderType: "",
  orderOtp: "",
  dinteroUrl: "",
  paymentDone: false,
  orderStatus: false,
  orderId: "",
  orderBYCoupon: false,
  tdcouponPaymentOtp: "",
  orderDetails: ""
};

let rootSlice = createSlice({
  name: "rootSlice",
  initialState,
  extraReducers: (builder) => {
    return builder.addCase(revertRoot, () => {
      storage.removeItem("persist:root");

      return initialState;
    });
  },

  reducers: {
    setOrderType: (state, action) => {
      state.orderType = action.payload;
    },

    saveOrderStatus: (state, action) => {
      state.dinteroUrl = action.payload.url;
      state.orderStatus = true;
      state.paymentDone = action.payload.paymentStatus;
      state.orderId = action.payload.orderId;
    },
    resetOrderUrl: (state, action) => {
      state.dinteroUrl = null;
    },

    updateOrder: (state, action) => {
      state.orderOtp = action.payload.otp;
      state.paymentDone = true;
      state.orderDetails = action.payload.orderData;
    },
    orderByCoupon: (state, action) => {
      state.orderBYCoupon = true;
      state.tdcouponPaymentOtp = action.payload.orderOtp;
      state.orderId = action.payload.orderId;
    },
    resetOrder: (state, action) => {
      state.orderId = "";
    }
  }
});

export const rootAction = rootSlice.actions;
export default rootSlice.reducer;
