import { Empty } from "antd";
import React from "react";
import searchGif from "../../assets/new/search.gif";

const App = () => (
  <Empty
    className="searchagain-img d-flex flex-column justify-content-center align-items-center"
    image={searchGif}
    imageStyle={{
      height: 200
    }}
    description={
      <span style={{ fontSize: "30px" }}>
        Vi finner ikke hvor du er, for å vise <br />
        deg nærmeste restauranter
        <br /> vennligst velg din posisjon
      </span>
    }
  />
);
export default App;
