import axios from "axios";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import { customerAction } from "../slices/customerslices";

export const saveCustomer = (payload) => {
  return async (dispatch) => {
    try {
      let response = await axios.post(
        `${apiUrl}${tDriveEndpoint}appuser/appuserweb`,
        payload
      );
      if (response.status == 200) {
        payload.id = response.data.data;
        dispatch(customerAction.saveCustomer(payload));
      }
    } catch (error) {
    }
  };
};
