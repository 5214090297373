import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  isError: false,
  errorMessage: ""
};
let error = createSlice({
  name: "error",
  initialState,
  extraReducers: (builder) => {
  },
  reducers: {
    saveError: (state, action) => {
      state.isError = true;
      state.errorMessage = action.payload.errorMessage
        ? action.payload.errorMessage
        : "";
    },
    clearError: (state, action) => {
      state.isError = false;
      state.errorMessage = "";
    }
  }
});

export const errorAction = error.actions;

export default error.reducer;
