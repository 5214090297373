import { createSlice } from "@reduxjs/toolkit";
import { revertAll, revertDis } from "../actions/rootaction";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

let initialState = {
  resLat: 0,
  cusLat: 0,
  resLng: 0,
  cusLng: 0,
  disRestToCus: 0,
  locDetails: "",
  fullAdress: ""
};

let distanceSlice = createSlice({
  name: "distanceSlice",
  initialState,
  extraReducers: (builder) => {
    return builder.addCase(revertDis, () => {
      storage.removeItem("persist:root");

      return initialState;
    });
  },
  reducers: {
    saveDistance: (state, action) => {
      state.resLat = action.payload.resLat;
      state.cusLat = action.payload.cusLat;
      state.resLng = action.payload.resLng;
      state.cusLng = action.payload.cusLng;
      state.disRestToCus = action.payload.disRestToCus;
    }
  }
});

export const distanceAction = distanceSlice.actions;
export default distanceSlice.reducer;
