import { createSlice } from "@reduxjs/toolkit";
import { revertRestaurant } from "../actions/rootaction";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

let initialState = {
  restaurant: [],
  reserror: { isError: false, resMessage: "" },
  selectedRestaurant: null
};
let restaurantSlice = createSlice({
  name: "restaurant",
  initialState,
  extraReducers: (builder) => {
    return builder.addCase(revertRestaurant, () => {
      storage.removeItem("persist:root");

      return initialState;
    });
  },
  reducers: {
    saveRestuarant: (state, action) => {
      state.restaurant = action.payload;
      if (action.payload.length > 0) {
        state.reserror.isError = false;
        state.reserror.resMessage = "";
      } else {
        state.reserror.isError = true;
        state.reserror.resMessage = action.payload;
      }
    },
    setError: (state, action) => {
      state.reserror.isError = true;
      state.reserror.resMessage = action.payload;
    },
    saveSelectedRestaurant: (state, action) => {
      state.selectedRestaurant = action.payload;
    },
    removeSelectedRestaurant: (state, action) => {
      state.selectedRestaurant = "";
    },
    removeRestaurants: (state, action) => {
      state.restaurant = "";
    }
  }
});

export const restaurantAction = restaurantSlice.actions;
export const restaurantReducer = restaurantSlice.reducer;
