import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { apiUrl, s3url } from "../../helper/endpoint";
import axios from "axios";
import { Card, Row, Col } from "antd";
import Form from "react-bootstrap/Form";
import { City } from "country-state-city";

export default function VendorList() {
  let [data, setData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  let navigate = useNavigate();
  const { Meta } = Card;
  let dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  let cities = City.getCitiesOfCountry("NO");
  let orderType = localStorage.getItem("vendorOrderType")
  console.log("order type ==== ", orderType)

  const selectedCity = (e) => {
    console.log("-----------------citySelected-----------", e.target.value);
    axios
      .post(`${apiUrl}/api/v1/vendor/apis/getVendors`, {
        cityName: e.target.value,
      })
      .then((res) => {
        console.log("--------------res-data----------------------", res.data);
        setVendorData(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };


  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/vendor/getVendorsPublic`
        );
        const data = response.data.data;
        setData(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [isLoading]);

  let cartSize;
  useEffect(() => {
    localStorage.setItem("cartData", []);
    localStorage.setItem("vendorOrderType", []);
    localStorage.setItem("vid", []);
    localStorage.setItem("vendorName", []);
    localStorage.setItem("tdServiceCharge", []);
  });

  const showProducts = (id, name, tdServiceCharge, cityDriveOut) => {
    localStorage.setItem("vid", id);
    localStorage.setItem("vendorName", name);
    localStorage.setItem("tdServiceCharge", tdServiceCharge);
    localStorage.setItem("cityDriveOut", cityDriveOut);
    navigate(`/VendorsProduct/${id}`, { state: { cartSize: cartSize } });
  };
  console.log("data", data);

  return (
    <Row className="p-5 mt-5" id="vendor">
      <Form.Select
        aria-label="Default select example"
        className="d-flex"
        onChange={(e) => selectedCity(e)}
      >
        <option value="" disabled selected>
          Select City
        </option>
        {cities.map((city) => (
          <option key={city.id} value={city.id}>
            {city.name}
          </option>
        ))}
      </Form.Select>

      {vendorData.map((vendor) => {
        return (
          <Col xs={24} md={8} className="colStyle">
            {" "}
            <Card
              hoverable
              onClick={() =>
                showProducts(vendor._id, vendor.name, vendor.tdServiceCharge, vendor.cityDriveOut)
              }
              key={vendor._id}
            >
              <div className="d-flex">
                <img
                  src={`${s3url}/${vendor.logo}`}
                  alt="offer available"
                  height={100}
                />
                <div
                  className="d-flex flex-column justify-content-center"
                  style={{ marginLeft: "15px" }}
                >
                  <h5>{vendor.name}</h5>
                  <p>{vendor.restaurantEmail}</p>
                  <p>{vendor.phoneNo}</p>
                  <p>Driveout Charges {vendor.cityDriveOut}</p>
                </div>
              </div>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
}
