import React from "react";

function Partnersbox(props){
    return(
      <div className="a-box">
          <div className="a-b-img">
             <img src={props.image} alt=""/>
          </div>
      </div>
    )
}
export default Partnersbox;