import OrderedProducts from "./OrderProducts";
import TotalTaxCal from "./TotalTaxCal";
import CartOrderButton from "./CartOrderButton";
import { useSelector } from "react-redux";

const ConfirmOrder = (props) => {
  let cart = useSelector((state) => state.cartReducer);

  return (
    <>
      <OrderedProducts
        cart={cart.cartItems && cart.cartItems.length ? cart.cartItems : []}
      />
      <TotalTaxCal cart={cart} />
      <CartOrderButton handleCancel={props.handleCancel}   />
    </>
  );
};
export default ConfirmOrder;
