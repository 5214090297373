import { Button, Modal, Row, Col } from "antd";
import { useState } from "react";
import { NavLink } from "react-router-dom";
const CartModal = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
    props.handleCartModalClose();
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal} block className="btnContainer">
        Continue
      </Button>
      <Modal
        title="Which delivery method do you want?"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row>
          <Col span={24} className="btns">
            <NavLink to="/RestaurantList/delivery" className="btnLink">
              Levering
            </NavLink>
            <NavLink to="/RestaurantList/pickup" className="btnLink">
              get self
            </NavLink>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default CartModal;
