import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";
import { NavLink, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkCouponPaymentStatus } from "../../../store/actions/couponAction";
import PrivacyHeader from "../../NavbarHeader/PrivacyHeader";
import { isMobile } from "react-device-detect";
import MobileNavBottom from "../../Footer/MobileNavBarBottom";

export default function SuceessCouponPurchase() {
  const [searchParams] = useSearchParams();

  let responseCode = searchParams.get("responseCode");
  let merchant_reference = searchParams.get("merchant_reference");
  let transaction_id = searchParams.get("transactionId")
    ? searchParams.get("transactionId")
    : searchParams.get("transaction_id");
  let accountId = searchParams.get("accountId");
  let cardNumber = searchParams.get("accountNumberMasked");
  let expiry = searchParams.get("accountAccessory");

  let root = useSelector((state) => state.couponReducer);
  let dispatch = useDispatch();
  let [isLoading, setStateLoading] = useState(true);

  useEffect(() => {
    let transactionObj;

    if (merchant_reference != "") {
      transactionObj = {
        status: "AUTHORIZED",
        transaction_id: transaction_id,
        vendorName: "Dintero",
        card: {
          brand: "Visa",
          masked_pan: "xxxx",
          expiry_date: "xxxx"
        }
      };
    } else {
      transactionObj = {
        status: "AUTHORIZED",
        account_id: accountId,
        transaction_id: transaction_id,
        vendorCode: accountId, ///this should be dynamic
        vendorName: "TDIPay",
        card: {
          brand: "Visa",
          masked_pan: cardNumber,
          expiry_date: expiry
        }
      };
    }

    let timer = setTimeout(() => {
      if (root.couponId && transaction_id) {
        dispatch(
          checkCouponPaymentStatus(
            root.couponId,
            transaction_id,
            transactionObj,
            responseCode
          )
        )
          .then(() => {
            setStateLoading(false);
          })
          .catch(() => {
            setStateLoading(false);
          });
      } else {
        setStateLoading(false);
      }
    }, 2000);
  });

  return (
    <>
      {!isMobile && <PrivacyHeader />}
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        {isLoading && (
          <Row className=" bg-white p-5">
            <Col
              span={24}
              style={{
                textAlign: "center",
                padding: "10px",
                color: "white"
              }}
            >
              <h3>Laster Vennligst vent....</h3>
            </Col>
          </Row>
        )}

        {root.paymentDone && !isLoading && (
          <Row className="justify-content-center flex-column bg-white p-5">
            <div className="py-3 d-flex justify-content-center flex-column align-items-center">
              <AiOutlineCheckCircle
                style={{
                  width: "150px",
                  height: "150px",
                  color: "#2563EB"
                }}
              />
              <h1 style={{ fontSize: "50px" }}>Gavekortet er opprettet.</h1>
            </div>
            <div>
              <p className="text-center">
                Takk Gavekortet ditt er generert. Vennligst sjekk "E-post" eller
                "SMS" sendt til telefonnummeret ditt for bekreftelse.
              </p>
            </div>
          </Row>
        )}

        {/*------------------not placed code start------------------*/}
        {!root.paymentDone && !isLoading && (
          <Row className="justify-content-center flex-column align-items-center bg-white p-5">
            <div className="py-3 d-flex justify-content-center flex-column align-items-center">
              <AiOutlineClose
                style={{
                  width: "150px",
                  height: "150px",
                  color: "red"
                }}
              />
              <h1 style={{ fontSize: "50px" }}>
                {" "}
                Et gavekort er ikke opprettet.{" "}
              </h1>
            </div>
            <div className="d-flex justify-content-center flex-column align-items-center">
              <p className="text-center">
                Vi er lei oss. men gavekortet ditt er IKKE generert.
              </p>
              <br />
              <NavLink to="/getTflCoupon" className="orderBtn">
                Please Try Again
              </NavLink>
            </div>
          </Row>
        )}
      </div>
    </>
  );
}
