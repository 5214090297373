import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Cards from "../Cards/Cards";
import { Row, Col, Carousel, Card } from "antd";
import moment from "moment";
import { s3url } from "../../helper/endpoint";
import {
  getRestaurants,
  sendLocationAction
} from "../../store/actions/locationaction";
import EmptyComponent from "../EmptyPage/EmpTy";
import { isMobile } from "react-device-detect";
import MobileNavBottom from "../Footer/MobileNavBarBottom";
import { socket } from "../../helper/socket";
import { restaurantAction } from "../../store/slices/restaurantslices";
import ScrollToTop from "../../components/ScrollToTop";
const RestaurantListComponent = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const restaurant = useSelector((state) => state.restaurantReducer.restaurant);
  const user = useSelector((state) => state.userReducer);
  const location = useSelector((state) => state.locationReducer);
  let orderType = useSelector((state) => state.rootReducer.orderType);
  const [isLoading, setIsLoading] = useState(true);
  const [tdDriveOutDelay, setTdDriveOutDelay] = useState(null);
  const [tdTakeAwayDelay, setTdTakeAwayDelay] = useState(null);

  const carouselRef = useRef(null);

  useEffect(() => {
    socket.on("treeDriveAvailSer", async (data) => {
      setIsLoading(true);
      dispatch(restaurantAction.removeRestaurants());
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(sendLocationAction());
  }, [dispatch]);

  useEffect(() => {
    // Extract delays from restaurant data
    if (restaurant && restaurant.length > 0) {
      const firstRestaurant = restaurant[0];
      setTdDriveOutDelay(firstRestaurant.tdDriveOutDelay);
      setTdTakeAwayDelay(firstRestaurant.tdTakeAwayDelay);
    }
  }, [restaurant]);

  useEffect(() => {
    dispatch(
      getRestaurants(
        {
          lat: location.latitude,
          lng: location.longitude
        },
        orderType,
        tdDriveOutDelay,
        tdTakeAwayDelay
      )
    )
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, location, orderType, tdDriveOutDelay, tdTakeAwayDelay]);

  const [advertisements, setAdvertisements] = useState([]);

  useEffect(() => {
    let ads = [];
    restaurant &&
      restaurant.map((res) => {
        let resAds = res.branchAds;
        if (resAds && resAds.length > 0) {
          let vids = resAds.filter((e) => e.type === "video");
          let images = resAds.filter((e) => e.type === "picture");
          let gifs = resAds.filter((e) => e.type === "gif");
          let html = resAds.filter((e) => e.type === "html");

          images[0]?.data.forEach((d) => ads.push(d));
          gifs[0]?.data.forEach((d) => ads.push(d));
          vids[0]?.data.forEach((d) => ads.push(d));
          html[0]?.data.forEach((d) => ads.push(d));
        }
      });

    setAdvertisements(ads);
  }, [restaurant]);

  const handleVideoEnd = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };
  const renderAd = (data) => {
    const adType =
      data.contentType === "video"
        ? "video"
        : data.contentType === "html"
        ? "html"
        : "picture";
    let url = `${s3url}/${data.url}`;

    let ele = <img src={url} alt={adType} height={"220px"} />;

    if (adType === "video") {
      ele = (
        <video controls width="100%" height="220px" onEnded={handleVideoEnd}>
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else if (adType === "html") {
      const startDate = new Date(
        data?.timePeriod?.startDate
      ).toLocaleDateString();
      const endDate = new Date(data?.timePeriod?.endDate).toLocaleDateString();
      return (
        <Card
          style={{
            backgroundImage: `url(${s3url}/${data.adBgUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "220px"
          }}
        >
          <Row>
            <Col span={12} className="text-white">
              <h3 style={{ height: "70px" }}>
                <b className="text-white">{data.productName}</b>
              </h3>
              <div className="d-flex mt-4">
                <p>
                  <strike>{data.prices.originalPrice}/-</strike>
                </p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <p>{data.prices.offerPrice}/-</p>
              </div>
              <div className="mt-3">
                <p>{startDate}</p>
                <p>{endDate}</p>
              </div>
            </Col>
            <Col
              span={12}
              className="advImg d-flex justify-content-center align-items-center"
            >
              <img src={`${s3url}/${data.url}`} />
            </Col>
          </Row>
        </Card>
      );
    }

    return ele;
  };


  return (
    <>
      {isMobile && (
        <Row className="pt-4 mt-5">
          {advertisements.length > 0 ? (
            <Carousel
              effect="fade"
              autoplay={true}
              autoplaySpeed={5000}
              ref={carouselRef}
              dots={false}
              className="mt-4"
            >
              {advertisements.map((data, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => console.log("--clicked on--", data._id)}
                  >
                    {renderAd(data)}
                  </div>
                );
              })}
            </Carousel>
          ) : (
            ""
          )}
        </Row>
      )}
      <Cards restaurant={restaurant} isLoading={isLoading} />
      {restaurant && restaurant.length === 0 && !isLoading && (
        <EmptyComponent />
      )}
      {isMobile && <MobileNavBottom />}
      <ScrollToTop />
    </>
  );
};
export default RestaurantListComponent;
