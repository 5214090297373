import React from "react";
import { Card, Row, Col, Button, Form, Input, Select } from "antd";

export const Delivery = () => {
  const onFinish = (values) => {};

  const onFinishFailed = (errorInfo) => {};
  return (
    <Row className="formWrapper">
      <Col span={14}>
        <div className="site-card-border-less-wrapper">
          <Card
            bordered={false}
            className="cardContainer"
            style={{
              background: "#fff"
            }}
          >
            <Col className="formHeader">Kundedetaljer</Col>

            <Form
              className="mainForm"
              name="basic"
              labelCol={{
                span: 8
              }}
              wrapperCol={{
                span: 24
              }}
              initialValues={{
                remember: true
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Fornavn"
                name="firstname"
                className="formField"
                rules={[
                  {
                    required: true,
                    message: "Please input your Fornavn!"
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Etternavn"
                name="lastname"
                className="formField"
                rules={[
                  {
                    required: true,
                    message: "Please input your Etternavn!"
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
            <Form
              className="mainForm"
              name="basic"
              labelCol={{
                span: 8
              }}
              wrapperCol={{
                span: 24
              }}
              initialValues={{
                remember: true
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name={["user", "email"]}
                label="E-mail"
                className="formField"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your E-mail!"
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Country Code" className="formField">
                <Select>
                  <Select.Option value="12345">12345</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name={["user", "phone"]}
                label="Phone"
                className="formField"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone!"
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
            <Row>
              <Col span={24} style={{ padding: "10px" }}>
                <h2>Adresse</h2>
              </Col>
            </Row>
            <Form
              className="mainForm"
              name="basic"
              labelCol={{
                span: 8
              }}
              wrapperCol={{
                span: 24
              }}
              initialValues={{
                remember: true
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Address"
                name="address"
                className="formField"
                rules={[
                  {
                    required: true,
                    message: "Please input your address!"
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Address"
                name="address"
                className="formField"
                rules={[
                  {
                    required: true,
                    message: "Please input your address!"
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
            <Form
              className="mainForm"
              name="basic"
              labelCol={{
                span: 8
              }}
              wrapperCol={{
                span: 24
              }}
              initialValues={{
                remember: true
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="By"
                name="by"
                className="formField"
                rules={[
                  {
                    required: true,
                    message: "Please input your by!"
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="State"
                name="state"
                className="formField"
                rules={[
                  {
                    required: true,
                    message: "Please input your state!"
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
            <Form
              className="mainForm"
              name="basic"
              labelCol={{
                span: 8
              }}
              wrapperCol={{
                span: 24
              }}
              initialValues={{
                remember: true
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Pin"
                name="pin"
                className="formField"
                rules={[
                  {
                    required: true,
                    message: "Please input your pin!"
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Land"
                name="land"
                className="formField"
                rules={[
                  {
                    message: "Please input your land!"
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
            <Row className="formFooter">
              <Col className="saveContainer" span={12}>
                <Button type="primary" className="saveBtn">
                  Lagre
                </Button>
              </Col>
            </Row>
          </Card>
        </div>
      </Col>
      <Col span={10} className="productTotalContainer">
        <div className="site-card-border-less-wrapper ">
          <Card bordered={false} className="cardSize" />
        </div>
      </Col>
    </Row>
  );
};
