import { useEffect } from "react";
import { useNavigate } from "react-router";
import { apiUrl, s3url } from "../../helper/endpoint";
import axios from "axios";
import { Card, Row, Col } from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
  getRestaurants,
  sendLocationAction
} from "../../store/actions/locationaction";
import { useState } from "react";
import EmptyComponent from "../EmptyPage/EmpTy";
import { isMobile } from "react-device-detect";
import MobileNavBottom from "../Footer/MobileNavBarBottom";

import { socket } from "../../helper/socket";

import { retailerAction } from "../../store/slices/retailerslice";

export default function RetailerList() {
  let navigate = useNavigate();
  let [data, setData] = useState([]);
  let dispatch = useDispatch();
  const retailer = useSelector((state) => state.retailerReducer.retailer);
  const location = useSelector((state) => state.locationReducer);
  let orderType = useSelector((state) => state.rootReducer.orderType);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    socket.on("treeDriveAvailSer", async (data) => {
      console.log("----Socket received----");

      setIsLoading(true);

      console.log(isLoading);
      dispatch(retailerAction.removeRetailer());
    });
  }, []);

  useEffect(() => {
    dispatch(sendLocationAction());
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.post(
          `${apiUrl}/api/v1/vendor/apis/getRetailers`,
          {
            latitude: location.latitude,
            longitude: location.longitude,
            branchType: "retailer"
          }
        );
        const data = response.data.data;
        setData(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [isLoading]);

  const showProducts = (id, name, tdServiceCharge, cityDriveOut) => {
    localStorage.setItem("retailerid", id);
    localStorage.setItem("retailerName", name);
    localStorage.setItem("tdServiceCharge", tdServiceCharge);
    localStorage.setItem("cityDriveOut", cityDriveOut);
    navigate(`/RetailerProduct/${id}`);
  };

  return (
    <>
      <Row className="p-5 mt-5" id="retailer">
        {data.map((retailer) => {
          return (
            <Col xs={24} md={8} className="colStyle">
              {" "}
              <Card
                hoverable
                onClick={() =>
                  showProducts(
                    retailer._id,
                    retailer.name,
                    retailer.tdServiceCharge,
                    retailer.cityDriveOut
                  )
                }
                key={retailer._id}
              >
                <div className="d-flex">
                  <img
                    src={`${s3url}/${retailer.logo}`}
                    alt="offer available"
                    height={100}
                  />
                  <div
                    className="d-flex flex-column justify-content-center"
                    style={{ marginLeft: "15px" }}
                  >
                    <h5>{retailer.name}</h5>
                    <p>{retailer.restaurantEmail}</p>
                    <p>{retailer.phoneNo}</p>
                  </div>
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
      {isMobile && <MobileNavBottom />}
    </>
  );
}
