import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import MobileNavBar from "../NavbarHeader/MobileNavBar";
import { redirect, useLocation } from "react-router-dom";

import { isMobile } from "react-device-detect";

let EzygoIndex = () => {
  let navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    if (location.pathname == "/") {
      return navigate("/home");
    }
  }, []);
  return (
    <>
      {!isMobile && <MobileNavBar />}
      <Outlet />
    </>
  );
};

export default EzygoIndex;
