import { Row, Col, Button } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import { cartAction } from "../../store/slices/cartslices";
import { useDispatch, useSelector } from "react-redux";

const OrderedProducts = (props) => {
  let dispatch = useDispatch();

  let restaurant = useSelector(
    (state) => state.restaurantReducer.selectedRestaurant
  );
  let root = useSelector((state) => state.rootReducer);

  const onRemoveProduct = (id, index) => {
    dispatch(
      cartAction.removeProductFromCart({
        id: id,
        index: index,
        restaurant: restaurant,
        orderType: root.orderType
      })
    );
  };
  const onRemoveExtraItems = (productId, productIndex, extraId, extraIndex) => {
    dispatch(
      cartAction.removeExtraItemFromCart({
        productId,
        productIndex,
        extraId,
        extraIndex
      })
    );
  };
  return (
    <div id="product-list">
      {props.cart.length &&
        props.cart.map((sinCart, index) => {
          let newPrice = sinCart.quantity * sinCart.incPrice;
          return (
            <div className="added-product mb-4" key={`${sinCart._id}+${index}`}>
              <Row className="justify-content-between">
                <Col
                  xs={10}
                  sm={10}
                  className="d-flex justify-content-between align-items-center"
                >
                  <h5>{sinCart.name}</h5>
                </Col>
                <Col
                  xs={1}
                  sm={2}
                  className="d-flex justify-content-between align-items-center"
                >
                  <b>*{sinCart.quantity}</b>
                </Col>
                <Col
                  xs={2}
                  sm={3}
                  className="d-flex justify-content-end align-items-center"
                >
                  <Button
                    type="primary"
                    onClick={() => onRemoveProduct(sinCart._id, index)}
                  >
                    <MinusOutlined />
                  </Button>
                </Col>

                <Col
                  xs={5}
                  sm={6}
                  className="d-flex justify-content-end align-items-center"
                >
                  <h6 className="itemName mb-0">Kr. {newPrice.toFixed(2)}</h6>
                </Col>
              </Row>

              {sinCart.instruction == "" ? (
                ""
              ) : (
                <Row className="py-3">
                  <Col xs={9} span={7}>
                    <h6 className="itemName">Bruksanvisning:</h6>
                  </Col>
                  <Col xs={15} span={16}>
                    <p className="itemName fst-italic intr-p billText">
                      {sinCart.instruction.join(", ")}
                    </p>
                  </Col>
                </Row>
              )}
              {sinCart.extraItems == "" ? (
                ""
              ) : (
                <Row>
                  <Col span={24} className="mt-3 mb-2">
                    <h6 className="itemName mb-0">Ekstra tillegg:</h6>
                  </Col>
                  <Col span={24}>
                    {sinCart.extraItems &&
                      sinCart.extraItems &&
                      sinCart.extraItems.map((ext, extraIndex) => {
                        return (
                          <Row
                            key={`${ext._id}+${extraIndex}`}
                            className="justify-content-between my-1 extraInOrder"
                          >
                            <Col
                              xs={10}
                              sm={10}
                              className="d-flex justify-content-between align-items-center"
                            >
                              <p>{ext.name}</p>
                            </Col>
                            <Col
                              xs={1}
                              sm={2}
                              className="d-flex justify-content-between align-items-center"
                            >
                              <p>
                                {" "}
                                <b>*1</b>
                              </p>
                            </Col>
                            <Col
                              xs={2}
                              sm={3}
                              className="d-flex justify-content-end align-items-center"
                            >
                              <Button
                                type="primary"
                                onClick={() =>
                                  onRemoveExtraItems(
                                    sinCart._id,
                                    index,
                                    ext._id,
                                    extraIndex
                                  )
                                }
                              >
                                <MinusOutlined />
                              </Button>
                            </Col>
                            <Col
                              xs={5}
                              sm={6}
                              className="d-flex justify-content-end align-items-center"
                            >
                              <h6 className="itemName mb-0">
                                Kr. {ext.incPrice.toFixed(2)}
                              </h6>
                            </Col>
                          </Row>
                        );
                      })}
                  </Col>
                </Row>
              )}
            </div>
          );
        })}
    </div>
  );
};
export default OrderedProducts;
