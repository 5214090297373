import React, { useState } from "react";
import Html5QrcodePlugin from "./Html5QrcodeScannerPlugin";
import ResultContainerPlugin from "./ResultContainerPlugin";

import { useNavigate } from "react-router";

export function ScanBarCode() {
  let navigate = useNavigate();
  const [decodedResults, setDecodedResults] = useState([]);
  const onNewScanResult = (decodedText, decodedResult) => {
    setDecodedResults((prev) => [...prev, decodedResult]);
  };

  return (
    <>
      <div className="scannerBg">
        <a
          onClick={() => {
            navigate("/home");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            fill="white"
          >
            <path d="M16.25 22.6 5.625 11.975 16.25 1.35l2.2 2.2-8.425 8.425L18.45 20.4Z" />
          </svg>
        </a>
      </div>
      <Html5QrcodePlugin
        className="pt-5"
        fps={10}
        qrbox={250}
        disableFlip={false}
        qrCodeSuccessCallback={onNewScanResult}
      />
      <ResultContainerPlugin results={decodedResults} />
    </>
  );
}
