import * as React from "react";
import { Box, Typography, Modal } from "@mui/material";
import "./modal.css";
export default function BasicModal(props) {
  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="boxContainer">
          <div className="alignContent"></div>

          <div className="columnRight">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ color: "#ffffff" }}
            >
              Følg med
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Vi kommer snart, til din by!
            </Typography>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
