import { createSlice } from "@reduxjs/toolkit";
import { revertCus } from "../actions/rootaction";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

let initialState = {
  _id: "",
  email: "",
  phone: "",
  lastName: "",
  firstName: "",
  countryCode: "",
  refferalCode: "",
  token:"",
};

let userSlice = createSlice({
  name: "User",
  initialState,
  extraReducers: (builder) => {
    return builder.addCase(revertCus, () => {
      storage.removeItem("persist:root");

      return initialState;
    });
  },
  reducers: {
    saveUser: (state, action) => {
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.lastName = action.payload.lastName;
      state.firstName = action.payload.firstName;
      state.countryCode = action.payload.countryCode;
      state._id = action.payload.id;
      state.refferalCode = action.payload.refferalCode;
      state.token = action.payload.token;
    },
    removeUser: (state, action) => {
      state.email = "";
      state.phone = "";
      state.lastName = "";
      state.firstName = "";
      state.countryCode = "";
      state._id = "";
      state.refferalCode = "";
      state.token = "";
    }
  }
});

export const userAction = userSlice.actions;
export default userSlice.reducer;