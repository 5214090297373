import React, { useState } from "react";
import { Button, Input, Collapse } from "antd";
import "../../../../topbar.css";
import { useSelector, useDispatch } from "react-redux";
import Productmodal from "./Modals/ConfirmProductModal";

const { TextArea } = Input;
const { Panel } = Collapse;
const ConfirmProducts = (props) => {
  let [isModalVisible, setIsModalVisible] = useState(false);
  let restaurant = useSelector(
    (state) => state.restaurantReducer.selectedRestaurant
  );
  const showModal = () => {
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {restaurant.availability.status === true ? (
        <Button type="primary" onClick={showModal} className="addBtn">
          {"Legg til"}
        </Button>
      ) : (
        <p className="bg-dark p-3 text-center text-white">
          {"Vi er ikke åpne ennå!"}
        </p>
      )}
      {isModalVisible && (
        <Productmodal
          closeModal={closeModal}
          ing={props.ing}
          singleProduct={props.singleProduct}
        />
      )}
    </>
  );
};
export default ConfirmProducts;
