import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import MobileNavBottom from "../Footer/MobileNavBarBottom";

import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";

import BottomFooter from "../Footer/BottomFooter";
import { isMobile } from "react-device-detect";
import { NavLink, useLocation } from "react-router-dom";
import PrivacyHeader from "../NavbarHeader/PrivacyHeader";

import { Col, FloatingLabel, Button, Form, Alert } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";

import { userAction } from "../../store/slices/userslices";

export default function Login() {
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  let user = useSelector((state) => state.userReducer);

  let responseError = useSelector((state) => state.errorReducer.errorMessage);

  let [alertMsg, setAlertMsg] = useState("");
  let [alertType, setAlertType] = useState("");
  let [showAlert, setShowAlert] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("47");

  const validateForm = (valid_inputs) => {
    for (let i = 0; i < valid_inputs.length; i++) {
      if (valid_inputs[i] == "") {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    console.log("-----------selectedCountryCode------", selectedCountryCode);
  }, [selectedCountryCode]);

  const handleCountryCodeChange = (e) => {
    console.log("country---------Code------------", e.target.value);
    setSelectedCountryCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let phoneNumber = e.target.inputPhone.value;
    let password = e.target.inputPassword.value;

    let inputFields = [phoneNumber, password];

    if (!validateForm(inputFields)) {
      console.log(phoneNumber, password);
      setShowAlert(true);
      setAlertType("danger");
      setAlertMsg("Please fill all the required fields!");
    } else {
      if (phoneNumber.length > 10 || phoneNumber.length < 8) {
        setShowAlert(true);
        setAlertType("danger");
        setAlertMsg("Please enter a valid Phone number!");
        return;
      }

      console.log("---posting data----");

      setShowAlert(true);
      setAlertType("warning");
      setAlertMsg("Vennligst vent...");

      //try{

      let formData = {
        inputRequestType: "post",
        phone: phoneNumber,
        password: password,
        countryCode: selectedCountryCode
      };

      await axios
        .post(`${apiUrl}${tDriveEndpoint}appuser/applogin`, formData)
        .then((response) => {
          console.log("---response server---", response.data.data.user);
          localStorage.setItem("token", response.data.data.token);

          if (response.data.status == 200) {
            console.log("----ok----", response);

            setShowAlert(true);
            setAlertType("success");
            setAlertMsg("Logged in successfully, redirecting to your profile.");

            let userPayload = {
              email: response.data.data.user.email,
              phone: response.data.data.user.phoneNumber,
              lastName: response.data.data.user.lastName,
              firstName: response.data.data.user.firstName,
              countryCode: response.data.data.user.countryCode,
              id: response.data.data.user._id,
              refferalCode: response.data.data.user.userReferralCode,
              token: response.data.data.user.token,
              pointsEarned: response.data.data.user.pointsEarned,
            };
            localStorage.setItem("token", userPayload.token);
            localStorage.setItem("userId", userPayload.id);
            localStorage.setItem("userFirstName", userPayload.firstName);
            localStorage.setItem("userLastName", userPayload.lastName);
            localStorage.setItem("userEmail", userPayload.email);
            localStorage.setItem("userCountryCode", userPayload.countryCode);
            localStorage.setItem("userPhone", userPayload.phone);
            localStorage.setItem("userPoints", userPayload.pointsEarned);

            dispatch(userAction.saveUser(userPayload), (window.location = "/"));
          } else {
            console.log("----error----");

            setShowAlert(true);
            setAlertType("danger");
            setAlertMsg(response.data.message);
          }
        })
        .catch((error) => {
          console.log("---error user---", error.message);
          setShowAlert(true);
          setAlertType("danger");
          setAlertMsg(
            "Feil: Ugyldig informasjon angitt, vennligst sjekk informasjonen din f�r du sender inn skjemaet."
          );
        });
    }
  };

  const navigate = useNavigate("");

  useEffect(() => {
    if (user.token) {
      navigate("/profile");
    }
    localStorage.clear();
  }, []);
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      {!isMobile && <PrivacyHeader />}
      {!isMobile && (
        <section className="mission profilePages  p-5">
          <div className="d-flex justify-content-center py-5 mt-5 ">
            <Col xs lg="6" className="pb-3 logBox">
              {" "}
              <h2 className="display-5 ">Logg inn</h2>
              <p className="my-2">
                <i>Eksisterende bruker, vennligst "Logg inn".</i>
              </p>
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Col className="d-flex">
                    <Form.Select
                      style={{ width: "25%" }}
                      aria-label="Velg landskode"
                      name="inputCountryCode"
                      onChange={handleCountryCodeChange}
                    >
                      <option value="47">+47</option>
                      <option value="91">+91</option>
                    </Form.Select>
                    <FloatingLabel
                      style={{ width: "100%" }}
                      controlId="floatingInputGrid"
                      label="Telefonnummer*"
                    >
                      <Form.Control
                        required
                        type="number"
                        placeholder="Telefonnummer"
                        name="inputPhone"
                      />
                    </FloatingLabel>
                  </Col>
                </Form.Group>
                <Form.Group className="row mb-3" controlId="formBasicPassword">
                  <Col md>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Passord*"
                    >
                      <Form.Control
                        required
                        type="password"
                        placeholder="Passord"
                        name="inputPassword"
                      />
                    </FloatingLabel>
                  </Col>
                </Form.Group>
                {showAlert && <Alert variant={alertType}>{alertMsg}</Alert>}{" "}
                <Button variant="primary" type="submit">
                  Logg Inn
                </Button>{" "}
                <div className="d-flex justify-content-between">
                  <u>
                    <NavLink to="/reset" className=" col-1">
                      Glemt passordet?
                    </NavLink>
                  </u>
                </div>
                <p className="py-3">
                  Ny bruker? &nbsp;
                  <NavLink to="/signup" className=" col-1">
                    <i>
                      <u>"Registrer deg" </u>
                    </i>
                  </NavLink>
                </p>
              </Form>
            </Col>
          </div>
        </section>
      )}
      {isMobile && (
        <section className="mission  p-5">
          <div className="d-flex justify-content-center">
            <Col xs lg="6" className="pb-3 logBox">
              {" "}
              <h2 className="display-5 ">Logg inn</h2>
              <p className="my-2">
                <i>Eksisterende bruker, vennligst "Logg inn".</i>
              </p>
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Col className="d-flex">
                    <Form.Select
                      style={{ width: "25%" }}
                      aria-label="Velg landskode"
                      name="inputCountryCode"
                      onChange={handleCountryCodeChange}
                    >
                      <option value="47">+47</option>
                      <option value="91">+91</option>
                    </Form.Select>
                    <FloatingLabel
                      style={{ width: "100%" }}
                      controlId="floatingInputGrid"
                      label="Telefonnummer*"
                    >
                      <Form.Control
                        required
                        type="number"
                        placeholder="Telefonnummer"
                        name="inputPhone"
                      />
                    </FloatingLabel>
                  </Col>
                </Form.Group>
                <Form.Group className="row mb-3" controlId="formBasicPassword">
                  <Col md>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Passord*"
                    >
                      <Form.Control
                        required
                        type="password"
                        placeholder="Passord"
                        name="inputPassword"
                      />
                    </FloatingLabel>
                  </Col>
                </Form.Group>
                {showAlert && <Alert variant={alertType}>{alertMsg}</Alert>}{" "}
                <Button variant="primary" type="submit">
                  Logg Inn
                </Button>{" "}
                <div className="d-flex justify-content-between">
                  <u>
                    <NavLink to="/reset" className=" col-1">
                      Glemt passordet?
                    </NavLink>
                  </u>
                </div>
                <p className="py-3">
                  Ny bruker? &nbsp;
                  <NavLink to="/signup" className=" col-1">
                    <i>
                      <u>"Registrer deg" </u>
                    </i>
                  </NavLink>
                </p>
              </Form>
            </Col>
          </div>
        </section>
      )}
      {!isMobile && <BottomFooter />}
      {isMobile && <MobileNavBottom />}
    </>
  );
}
