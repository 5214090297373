import React, { useState } from "react";
import axios from "axios";

import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";

import BottomFooter from "../Footer/BottomFooter";
import MobileNavBottom from "../Footer/MobileNavBarBottom";

import { Row, Col, FloatingLabel, Button, Form, Alert } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import PrivacyHeader from "../NavbarHeader/PrivacyHeader";

export default function Verify() {
  const dispatch = useDispatch();

  let responseError = useSelector((state) => state.errorReducer.errorMessage);

  let [alertMsg, setAlertMsg] = useState("");
  let [alertType, setAlertType] = useState("");
  let [showAlert, setShowAlert] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let phoneNumber = e.target.inputPhone.value;
    let otp = e.target.inputOtp.value;

    if (otp == "" || phoneNumber == "") {
      setShowAlert(true);
      setAlertType("danger");
      setAlertMsg("Phone number & Verification code is required.");
    } else {
      console.log("---posting data----");

      setShowAlert(true);
      setAlertType("warning");
      setAlertMsg("Vennligst vent...");

      //try{

      let formData = {
        otp: otp,
        phone: phoneNumber
      };

      await axios
        .post(`${apiUrl}${tDriveEndpoint}appuser/otpverifcation`, formData)
        .then((response) => {
          console.log("---response server---", response);

          if (response.data.status == 200) {
            console.log("----ok----");

            setShowAlert(true);
            setAlertType("success");
            // setAlertMsg('Account created, please wait...');
            setAlertMsg(response.data.message);
            window.location = "login";
          } else {
            console.log("----error----");

            setShowAlert(true);
            setAlertType("danger");
            setAlertMsg(response.data.message);
          }
        })
        .catch((error) => {
          console.log("---error user---", error.response.data.message);
          setShowAlert(true);
          setAlertType("danger");
          setAlertMsg(error.response.data.message);
        });
    }
  };

  return (
    <>
      {" "}
      {!isMobile && <PrivacyHeader />}
      {isMobile && (
        <section className="mission  p-5">
          <div className="d-flex justify-content-center">
            <Col xs lg="6" className="logBox">
              <h1 className="display-5 mt-0  mb-2">Bekreft kontoen din</h1>
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Col md>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Telefonnummer*"
                    >
                      <Form.Control
                        required
                        type="number"
                        placeholder="Telefonnummer"
                        name="inputPhone"
                      />
                    </FloatingLabel>
                  </Col>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Col md>
                    <FloatingLabel controlId="floatingInputGrid" label="OTP*">
                      <Form.Control
                        required
                        type="number"
                        placeholder="OTP"
                        name="inputOtp"
                      />
                    </FloatingLabel>
                    <p className="mb-4 pt-2">
                      <i>
                        *Vennligst skriv inn bekreftelseskoden du har mottatt på
                        ditt registrerte mobilnummer.
                      </i>
                    </p>
                  </Col>
                </Form.Group>

                {showAlert && <Alert variant={alertType}>{alertMsg}</Alert>}

                <Button variant="primary" type="submit">
                  Verifiser konto
                </Button>
              </Form>
            </Col>
          </div>
        </section>
      )}
      {!isMobile && (
        <section className="mission profilePages p-5">
          <div className="container py-5 mt-5">
            <Row className="justify-content-md-center align-items-center flex-column">
              <Col xs lg="6" className="pb-3 logBox">
                <h1 className="display-5 mt-0 mb-2">Bekreft kontoen din</h1>
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Col md>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Telefonnummer*"
                      >
                        <Form.Control
                          required
                          type="number"
                          placeholder="Telefonnummer"
                          name="inputPhone"
                        />
                      </FloatingLabel>
                    </Col>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Col md>
                      <FloatingLabel controlId="floatingInputGrid" label="OTP*">
                        <Form.Control
                          required
                          type="number"
                          placeholder="OTP"
                          name="inputOtp"
                        />
                      </FloatingLabel>
                      <p className="mb-4 pt-2">
                        <i>
                          * Vennligst skriv inn bekreftelseskoden du har mottatt
                          på ditt registrerte mobilnummer.
                        </i>
                      </p>
                    </Col>
                  </Form.Group>

                  {showAlert && <Alert variant={alertType}>{alertMsg}</Alert>}

                  <Button variant="primary" type="submit">
                    Verifiser konto
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </section>
      )}
      {!isMobile && <BottomFooter />}
      {isMobile && <MobileNavBottom />}
    </>
  );
}
