import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Card, Col, Button, Checkbox, Alert } from "antd";
import { useNavigate } from "react-router";
import { isMobile } from "react-device-detect";
import smallCoin from "../../assets/SMALLCOIN.png";
import MobileNavBottom from "../Footer/MobileNavBarBottom";
import { s3url, apiUrl } from "../../helper/endpoint";
import MapForm from "../Checkout/Forms/MapForm";
import axios from "axios";

const TflMarketCart = (props) => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let location = useLocation();
  let customerLocation = useSelector(
    (state) => state.locationReducer.locationAddress
  );
  const { totalAmount, totalWeight } = location.state;
  // console.log("location", location.state);

  const [cartData, setCartData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [minusCoins, setMinusCoins] = useState(0);
  const [totalToPay, setTotalToPay] = useState(totalAmount);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [useTflCoins, setUseTflCoins] = useState(false);

  let userPoints = localStorage.getItem("userPoints");
  let pointsValue = localStorage.getItem("pointsValue");
  let userId = localStorage.getItem("userId");
  let userFirstName = localStorage.getItem("userFirstName");
  let userLastName = localStorage.getItem("userLastName");
  let userEmail = localStorage.getItem("userEmail");
  let userCountryCode = localStorage.getItem("userCountryCode");
  let userPhone = localStorage.getItem("userPhone");
  const token = localStorage.getItem("token");

  useEffect(() => {
    let data = localStorage.getItem("TFLCartData");
    if (data) {
      setCartData(JSON.parse(data));
    }
    if (location.state && location.state.deliveryCharges) {
      const { deliveryCharges, totalLength, totalBreadth, totalHeight } = location.state;

      console.log("---delivery charges---", totalWeight, location.state.deliveryCharges);
      
      let prices = [];
      let allPrices = [];
      for (let i = 0; i < deliveryCharges.length; i++) {
        allPrices.push(deliveryCharges[i].price);

        if(
          totalLength <= deliveryCharges[i].dimensions.length && totalBreadth <= deliveryCharges[i].dimensions.breadth &&
          totalHeight <= deliveryCharges[i].dimensions.height
        ){

          if(totalWeight >= deliveryCharges[i].weight.min && totalWeight <= deliveryCharges[i].weight.max){
            prices.push(deliveryCharges[i].price);
          }

        }

      }

      let charge = (allPrices.length > 0)? Math.min(...allPrices) : Math.max(...prices);

      setDeliveryCharges(charge);
    }
  }, [location.state, totalToPay]);

  useEffect(() => {
    let totalAfterDeliveryCharges = totalToPay + deliveryCharges;

    console.log("total after delivery charges: " + totalAfterDeliveryCharges);
    setTotalToPay(totalAfterDeliveryCharges);
  }, [deliveryCharges]);

  const onChange = (e) => {
    let cartAmount = totalAmount;
    setUseTflCoins(e.target.checked);
    if (e.target.checked) {
      let coinsUsed = (userPoints * 80) / 100;
      let discountCalc = coinsUsed * pointsValue;
      userPoints = userPoints - coinsUsed;
      let unUsedCoins = 0;

      if (discountCalc > cartAmount) {
        // unUsedCoins = discountCalc - totalAmount; //80-65 = 15
        unUsedCoins = discountCalc - cartAmount; // If discount is greater than cart amount
        unUsedCoins = unUsedCoins / pointsValue; // convert 15 to coins

        userPoints += Math.floor(unUsedCoins); /// add unused coins back to remaining coins, if val in float use floor
        coinsUsed -= unUsedCoins;

        cartAmount = 0;
      } else {
        cartAmount -= discountCalc;
      }

      cartAmount += deliveryCharges;

      console.log("---amount, coins---", cartAmount, coinsUsed);

      setTotalToPay(cartAmount);
      setMinusCoins(coinsUsed);
    } else {
      cartAmount += deliveryCharges;
      setTotalToPay(cartAmount);
      setMinusCoins(0);
    }
  };

  let onCreateOrder = async (cart) => {
    console.log("cart: onCreateOrder ", cart);
    setDisabled(true);
    let orderPayload = {
      items: cartData,
      customerData: {
        countryCode: userCountryCode,
        email: userEmail,
        firstName: userFirstName,
        lastName: userLastName,
        phoneNumber: userPhone,
        address: customerLocation
      },
      coinsUsed: minusCoins,
      appUserId: userId,
      customerType: "IOS",
      orderFrom: "TDP",
      paymentMode: "card"
    };

    console.log("---order payload---", orderPayload);
    // return;
    try {
      let response = await axios.post(
        `${apiUrl}/api/v1/treeDrive/treedrivepayment/createordertfl`,
        orderPayload,
        {
          headers: {
            "x-auth-token": `${token}` // Include the token in the header
          }
        }
      );
      if (response.status === 200) {
        setDisabled(false);
        let orderResponseUrl = response.data.data.data.url;
        window.location.replace(orderResponseUrl);
      }
      console.log("response", response.data);
    } catch (error) {
      setDisabled(true);
      console.log("error: ", error);
    }
  };

  return (
    <>
      {isMobile && (
        <Row className="mb-5 pb-5">
          <Col span={24} className="p-3">
            <Button onClick={() => navigate("/tflMarket")}>Tilbake</Button>
            <MapForm />
          </Col>
          <Col
            span={24}
            className="px-3 pt-3"
            style={{ height: "40vh", overflowY: "scroll" }}
          >
            {cartData.map((item) => (
              <Card key={item._id} className="mb-3">
                <Row className="d-flex justify-content-between">
                  <Col span={8}>
                    <img
                      style={{ width: "50px" }}
                      alt="example"
                      src={`${s3url}/${item.url}`}
                    />
                  </Col>
                  <Col
                    span={15}
                    className="d-flex flex-column justify-content-between align-items-start"
                  >
                    <p>
                      <b>{item.productName}</b> &nbsp;*{item.quantity}
                    </p>
                    <p>{item.amount * item.quantity} Kr.</p>
                    <p>
                      l{item.dimensions.length}*b{item.dimensions.breadth}*h
                      {item.dimensions.height}
                    </p>
                  </Col>
                </Row>
              </Card>
            ))}
          </Col>
          <Col
            span={24}
            className="w-100 position-fixed"
            style={{ bottom: "50px" }}
          >
            <Card
              className="mb-3 border-0"
              actions={[
                <Button
                  type="primary"
                  className="w-100 saveBtn"
                  block
                  // disabled={disabled || !props.isEnablePay}
                  // make payment success page
                  onClick={() => onCreateOrder(cartData)}
                >
                  Pay &nbsp;{totalToPay} Kr.
                </Button>
              ]}
            >
              {userPoints >= 0 ? (
                <>
                  {userPoints >= 10 ? (
                    <>
                      {" "}
                      <Checkbox onChange={onChange}>
                        &nbsp;Betal med{" "}
                        <b>
                          &nbsp;TFL Coins &nbsp;{userPoints - minusCoins}
                          <img src={smallCoin} alt="TFL Coin" />
                        </b>
                        .
                      </Checkbox>
                      <hr />
                    </>
                  ) : (
                    <>
                      <Alert
                        style={{ borderRadius: "8px", padding: "5px 10px" }}
                        message={
                          <span style={{ fontSize: "12px" }}>
                            Minimum 10 <img src={smallCoin} alt="TFL Coin" />{" "}
                            nødvendig, men du har bare {userPoints}{" "}
                            <img src={smallCoin} alt="TFL Coin" /> venstre.
                          </span>
                        }
                        type="info"
                        showIcon
                      />
                      <hr />
                    </>
                  )}
                </>
              ) : (
                ""
              )}

              <h5>Regningsdetaljer:</h5>
              <div className="d-flex justify-content-between">
                <p>Totall</p>
                <p>
                  {totalAmount}
                  Kr.
                </p>
              </div>
              {minusCoins !== 0 && (
                <div className="d-flex justify-content-between">
                  <p>Coin Used</p>
                  <p>
                    -{minusCoins} <img src={smallCoin} alt="TFL Coin" />
                  </p>
                  {/* <p>-{minusCoins} Kr.</p> */}
                </div>
              )}
              <div className="d-flex justify-content-between">
                <p>Utkjøring Pris</p>
                <p>+{deliveryCharges} Kr.</p>
              </div>
              {/* <div className="d-flex justify-content-between">
                <p>Gjenstående å betale</p>
                <p>{totalToPay} Kr.</p>
              </div> */}
              {minusCoins !== 0 && (
                <p>
                  Du sparer <b>{minusCoins} Kr.</b> på denne ordren.
                </p>
              )}
            </Card>
          </Col>
        </Row>
      )}
      {isMobile && <MobileNavBottom />}
    </>
  );
};

export default TflMarketCart;
