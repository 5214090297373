import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  longitude: "",
  latitude: "",
  locationName: "",
  locDetails: {},
  locationAddress: "",
  reserror: { isError: false, resMessage: "" }
};

let locstionSlice = createSlice({
  name: "locationSlice",
  initialState,
  reducers: {
    saveAddress: (state, action) => {
      state.longitude = action.payload.lng ? action.payload.lng : "";
      state.latitude = action.payload.lat ? action.payload.lat : "";
      state.locDetails = action.payload.locDetails
        ? action.payload.locDetails
        : {};
      state.locationName = action.payload.locationName
        ? action.payload.locationName
        : "";
      state.locationAddress = action.payload.address
        ? action.payload.address
        : "";
      state.reserror.isError = false;
      state.reserror.resMessage = "";
    },
    saveError: (state, action) => {
      state.reserror.isError = true;
      state.reserror.resMessage = action.payload;
    }
  }
});

export const locationAction = locstionSlice.actions;
export default locstionSlice.reducer;
