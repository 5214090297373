import React, { useEffect, useState, useRef } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCouponValue } from "../../store/actions/couponAction";

function ApplyCouponModal(props) {
  const [show, setShow] = useState(true);
  const [couError, setCouError] = useState(false);
  const [loading, setloading] = useState(false);

  let couponReducer = useSelector((state) => state.couponReducer);
  let isFirst = false;

  let couponRef = useRef();
  let dispatch = useDispatch();
  const handleClose = () => {
    setShow(false);
    props.onCloseApplycouponModal();
  };

  useEffect(() => {
    let coupanValue = couponReducer.couponValue;
    let isFetched = couponReducer.isFetched;

    if (coupanValue && isFetched) {
      props.onCloseApplycouponModal();
      props.updateCouponValue(coupanValue);
    }

    if (!coupanValue && isFetched) {
      setCouError(true);
    }
  }, [couponReducer]);

  const handleShow = () => setShow(true);
  const onClickCoupon = () => {
    let coupanCode = couponRef.current.value;
    if (coupanCode.trim() == "") {
      setCouError(true);
    } else {
      // console.log("else");
      dispatch(
        getCouponValue({
          coupanCode: coupanCode,
          branchId: props.branchId
        })
      )
        .then((red) => {
          setloading(false);
        })
        .catch((err) => {
          setCouError(true);
          setloading(false);
        });
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>
            Apply Gift Card Code
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {loading && (
              <div style={{ textAlign: "center" }}>
                {<Spinner animation="grow" style={{ color: "black" }} />}
              </div>
            )}

            {!loading && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                {/* <Form.Label>Gift Card Code</Form.Label> */}
                <Form.Control
                  type="text"
                  ref={couponRef}
                  placeholder="Gift Card Code"
                  autoFocus
                />
                {couError && (
                  <Form.Text className="text-muted">
                    Code is invalid or expired
                  </Form.Text>
                )}
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        {!loading && (
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Greit
            </Button>
            <Button variant="primary" onClick={onClickCoupon}>
              Apply Code
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ApplyCouponModal;
