import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/Asset 1.png";
import ProfileIcon from "../../assets/new/profileIcon.png";
import "aos/dist/aos.css";
import { customerAction } from "../../store/slices/customerslices";

export default function MobileNavTop() {
  let user = useSelector((state) => state.userReducer);
  useEffect(() => {
    dispatch(customerAction.removeCustomer());
  }, []);

  let dispatch = useDispatch();

  return (
    <Row className="position-fixed mobileNavTop px-3 py-2">
      {" "}
      <Col span={14}>
        {" "}
        <img
          src={logo}
          alt="logo"
          className="logoSize"
          width={"80px"}
          height={"60px"}
        />
      </Col>
      <Col span={10} className="d-flex justify-content-end">
        {user.token != "" ? (
          <NavLink to="/profile" className=" ">
            <img src={ProfileIcon} />
          </NavLink>
        ) : (
          <NavLink to="/login" className="">
            <img src={ProfileIcon} />
          </NavLink>
        )}
      </Col>
    </Row>
  );
}
