import React from "react";

function filterResults(results) {
  let filteredResults = [];
  for (var i = 0; i < results.length; ++i) {
    if (i === 0) {
      filteredResults.push(results[i]);
      continue;
    }

    if (results[i].decodedText !== results[i - 1].decodedText) {
      filteredResults.push(results[i]);
    }
  }
  return filteredResults;
}

const ResultContainerPlugin = (props) => {
  const results = filterResults(props.results);
  return (
    <div className="Result-container">
      <div className="Result-section">
        {results.map((result) => {
          console.log(result);
          return (
            <>
            <br/>
            <br/>
            <p>Click below button to proceed with your booking.</p>
            <br/>
              <u><i>
                <a href={result.decodedText}>Book Now</a>
                </i></u>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ResultContainerPlugin;
