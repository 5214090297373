import React, { useState, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import logo from "../../assets/Asset 1.png";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { rootAction } from "../../store/slices/rootSlices";
import "./mobilenavbar.css";
import {
  revertCart,
  revertDis,
  revertLocation,
  revertProduct,
  revertRestaurant,
  revertRoot,
  revertCou
} from "../../store/actions/rootaction";

function MobileNavBar(props) {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  let dispatch = useDispatch();
  let location = useLocation();

  useEffect(() => {
    if (location.pathname == "/home") {
      dispatch(revertCart());
      dispatch(revertDis());
      dispatch(revertLocation());
      dispatch(revertProduct());
      dispatch(revertRestaurant());
      dispatch(revertRoot());
      dispatch(revertCou());
    }
  }, [location.pathname]);

  let navigate = useNavigate();
  let user = useSelector((state) => state.userReducer);

  return (
    <nav className="navbar">
      <Container>
        <div className="nav-container">
          <NavLink to="/home" className="nav-logo col-1">
            <img
              src={logo}
              alt="logo"
              className="logoSize"
              width={"80px"}
              height={"60px"}
            />
            {/* <h2>
              <i>EzyGo</i>
            </h2> */}
          </NavLink>

          <div>
            <a
              className="aboutNavLink"
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(rootAction.setOrderType("Drive Out"));
                navigate("/restaurants");
              }}
            >
              BESTILL PÅ NETT
            </a>
            <a
              href="https://ezygo.no/"
              className="aboutNavLink"
              style={{ cursor: "pointer" }}
              target="_blank"
            >
              OM OSS
            </a>
            {/* <a
              href="#about"
              className="aboutNavLink"
              style={{ cursor: "pointer" }}
            >
              Om Oss
            </a> */}
            {/* <a
              href="#app-download"
              className="aboutNavLink"
              style={{ cursor: "pointer" }}
            >
              Last ned app
            </a> */}
            <a
              href="faqs"
              className="aboutNavLink"
              style={{ cursor: "pointer" }}
            >
              FAQ's
            </a>
            {user.token != "" ? (
              <NavLink to="/profile" className=" aboutNavLink col-1">
                Profil
              </NavLink>
            ) : (
              <NavLink to="/login" className=" aboutNavLink col-1">
                MELDE DEG PÅ/Logg inn
              </NavLink>
            )}
          </div>
        </div>
      </Container>
    </nav>
  );
}

export default MobileNavBar;
