import OrderNowButon from "../../../OrderNowButton";
import { s3url } from "../../../../../../helper/endpoint";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Divider, Typography } from "antd";
import {
  getIncreasedPrices,
  getProductsIngrident
} from "../../../Helper/producthelper";

const ProductComponent = (props) => {
  const { Text } = Typography;
  const cart = useSelector((state) => state.cartReducer);
  const [ing, setIng] = useState([]);
  const [price, setPrice] = useState(0);

  const [orignalPrice, setOrignalPrice] = useState(0);
  const [showOffer, setShowOffer] = useState(false);

  let dispatch = useDispatch();

  useEffect(() => {
    if (props.singleProduct) {
      let ingridents = getProductsIngrident(props.sinIng, props.singleProduct);
      setIng(ingridents);
    }
  }, [props.singleProduct, props.sinIng]);

  useEffect(() => {
    if (props.singleProduct && props.singleProduct.price) {
      console.log(
        "-------------singleproduct------------",
        props.singleProduct
      );

      let finalPrice = 0;
      let showOffer = false;

      let increasedPrice = getIncreasedPrices(
        props.singleProduct,
        props.incPricePer
      );

      finalPrice = increasedPrice;

      console.log("first", increasedPrice);

      if (props.singleProduct.offerEnable) {
        let todayDate = new Date();
        let startDate = new Date(props.singleProduct.offer.startDate);
        let endDate = new Date(props.singleProduct.offer.endDate);

        if (todayDate >= startDate && todayDate <= endDate) {
          let prod = {};
          prod.price = props.singleProduct.offer.offerPrice;

          let offerPriceIncreased = getIncreasedPrices(prod, props.incPricePer);

          finalPrice = offerPriceIncreased;

          showOffer = true;
        }
      }

      setPrice(parseFloat(finalPrice).toFixed(1));
      setOrignalPrice(increasedPrice);
      setShowOffer(showOffer);
    }
  }, [props.singleProduct, props.incPricePer]);

  return (
    props.singleProduct && (
      <>
        <div className="p-4">
          <div className="single-img" style={{ textAlign: "center" }}>
            <img
              src={`${s3url}/${props.singleProduct.thumbnail}`}
              style={{ width: "300px" }}
            />
          </div>
          <Divider />
          <div className="productDescription py-3">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="productName mb-0">{`${props.singleProduct.name}`}</h5>

              {props.singleProduct.offerEnable && showOffer ? (
                <div className="d-flex justify-content-end">
                  <h5 className="productName mb-0  ">
                    {` (Kr. ${Math.ceil(price)})`}
                  </h5>
                  <h5
                    className="productName mb-0 d-flex"
                    style={{
                      textDecorationLine: "line-through",
                      padding: "0px 20px"
                    }}
                  >
                    (Kr. {Math.ceil(orignalPrice)})
                  </h5>
                </div>
              ) : (
                <div>
                  <h5 className="productName  mb-0 ">
                    (Kr. {Math.ceil(orignalPrice)})
                  </h5>
                </div>
              )}
            </div>
          </div>
          <Divider />
          <div>
            {ing == "" ? (
              ""
            ) : (
              <p>
                Ingredienser :
                {ing.map((sin) => {
                  return (
                    <span style={{ margin: "0 0.5rem" }} key={sin._id}>
                      {sin.name},
                    </span>
                  );
                })}
              </p>
            )}

            {props.singleProduct.ingredentsDesc == "" ? (
              ""
            ) : (
              <p>
                Beskrivelse: &nbsp;
                {props.singleProduct.ingredentsDesc.split("\n").map((e) => {
                  return (
                    <span>
                      {e} <br></br>
                    </span>
                  );
                })}
              </p>
            )}
          </div>
        </div>
        <div>
          <OrderNowButon
            isMobile={props.isMobile}
            className="btnWrap"
            handleCancelModal={props.handleCancelModal}
            ing={ing}
            singleProduct={{ ...props.singleProduct, incPrice: price }}
          />
        </div>
      </>
    )
  );
};
export default ProductComponent;
