import axios from "axios";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import { productActions } from "../slices/productslices";
import { errorAction } from "../slices/errorSlices";

export const getSuperCategory = (resId, type) => {
  return async (dispatch) => {
    try {
      let response = await axios.post(
        `${apiUrl}${tDriveEndpoint}categoriesfind/tdlproducts`,
        {
          branchId: resId,
          type: type
        }
      );

      if (response.status == 200) {
        dispatch(productActions.saveSuperCategory(response.data));
      }
    } catch (error) {
      dispatch(
        errorAction.saveError({
          errorMessage: error.message ? error.message : error,
          isError: true
        })
      );
    }
  };
};