import React from "react";
import ConfirmPorduct from "./SuperCategory/ProductCategory/Product/ConfirmPorduct";
const OrderNowButon = (props) => {
  return (
        <ConfirmPorduct
          link={"BESTIL NA"}
          isMobile={props.isMobile}
          onCloseMobileProductModal={props.onCloseMobileProductModal}
          handleCancelModal={props.handleCancelModal}
          ing={props.ing}
          singleProduct={props.singleProduct}
        />
  );
};
export default OrderNowButon;
