import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col } from "antd";
import { Button } from "react-bootstrap";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import { useNavigate } from "react-router";
import { NavLink, useSearchParams } from "react-router-dom";
import BottomFooter from "../Footer/BottomFooter";
import MobileNavBottom from "../Footer/MobileNavBarBottom";

import { FloatingLabel, Form } from "react-bootstrap";

import Alert from "react-bootstrap/Alert";
import { isMobile } from "react-device-detect";

import { useSelector, useDispatch } from "react-redux";
import PrivacyHeader from "../NavbarHeader/PrivacyHeader";

export default function Signup() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  let user = useSelector((state) => state.userReducer);
  let responseError = useSelector((state) => state.errorReducer.errorMessage);

  let [alertMsg, setAlertMsg] = useState("");
  let [alertType, setAlertType] = useState("");
  let [showAlert, setShowAlert] = useState(false);

  let [refCode, setRefCode] = useState("");
  useEffect(() => {
    let refCode = searchParams.get("ref");
    if (refCode) {
      setRefCode(refCode);
    }
  }, []);

  const validateForm = (valid_inputs) => {
    for (let i = 0; i < valid_inputs.length; i++) {
      if (valid_inputs[i] == "") {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let firstName = e.target.inputFirstName.value;
    let lastName = e.target.inputLastName.value;
    let email = e.target.inputEmail.value;
    let phone = e.target.inputPhone.value;
    let referral = e.target.inputReferral.value;
    let password = e.target.inputPassword.value;
    let conPassword = e.target.inputConPassword.value;
    let countryCode = e.target.inputCountryCode.value;

    let inputFields = [firstName, email, phone, password, conPassword];

    if (!validateForm(inputFields)) {
      console.log(firstName, lastName, email, phone, password, referral);
      setShowAlert(true);
      setAlertType("danger");
      setAlertMsg("Please fill all the required fields!");
    } else {
      if (phone.length > 10 || phone.length < 8) {
        setShowAlert(true);
        setAlertType("danger");
        setAlertMsg("Please enter a valid Phone number!");
        return;
      }

      if (password != conPassword) {
        setShowAlert(true);
        setAlertType("danger");
        setAlertMsg("Please confirm your password!");
        return;
      }

      console.log("---posting data----");

      setShowAlert(true);
      setAlertType("warning");
      setAlertMsg("Vennligst vent...");

      //try{

      let formData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        countryCode: countryCode,
        password: password,
        referral: referral,
        isAgreed: true
      };

      console.log("---phone, countrycode---", formData);

      await axios
        .post(`${apiUrl}${tDriveEndpoint}appuser/appuser`, formData)
        .then((response) => {
          console.log("---response server---", response);

          if (response.data.status == 200) {
            setShowAlert(true);
            setAlertType("success");
            setAlertMsg(response.data.message);
            window.location = "verify";
          } else {
            setShowAlert(true);
            setAlertType("danger");
            setAlertMsg(response.data.message);
          }
        })
        .catch((error) => {
          console.log("---error user---", error.response.data.message);
          setShowAlert(true);
          setAlertType("danger");
          setAlertMsg(error.response.data.message);
        });
    }
  };

  const navigate = useNavigate("");

  useEffect(() => {
    if (user.token) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      {!isMobile && <PrivacyHeader />}
      {!isMobile && (
        <section className="mission profilePages p-5">
          <div className="d-flex justify-content-center align-items-center py-5 mt-5 signup">
            <Col xs lg="9" className="logBox">
              <h2 className="display-5 mb-2">Opprett din profil</h2>
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="row mb-3" controlId="inputFirstName">
                  <Col md>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Fornavn*"
                    >
                      <Form.Control
                        required
                        type="text"
                        placeholder="Fornavn"
                        name="inputFirstName"
                      />
                    </FloatingLabel>
                  </Col>

                  <Col md>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Etternavn*"
                    >
                      <Form.Control
                        required
                        type="text"
                        placeholder="Etternavn"
                        name="inputLastName"
                      />
                    </FloatingLabel>
                  </Col>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FloatingLabel controlId="floatingInputGrid" label="E-post*">
                    <Form.Control
                      required
                      type="email"
                      placeholder="name@example.com"
                      name="inputEmail"
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group className="row mb-3" controlId="formBasicEmail">
                  <Col className="d-flex">
                    <Form.Select
                      style={{ width: "25%" }}
                      aria-label="Velg landskode"
                      name="inputCountryCode"
                    >
                      <option value="47">+47</option>
                      <option value="91">+91</option>
                    </Form.Select>
                    <FloatingLabel
                      style={{ width: "100%" }}
                      controlId="floatingInputGrid"
                      label="Telefonnummer*"
                    >
                      <Form.Control
                        required
                        type="number"
                        placeholder="Telefonnummer"
                        name="inputPhone"
                      />
                    </FloatingLabel>
                  </Col>
                  <Col md>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Henvisningskode (hvis noen)"
                    >
                      <Form.Control
                        defaultValue={refCode}
                        type="text"
                        placeholder="Referral Code"
                        name="inputReferral"
                      />
                    </FloatingLabel>
                  </Col>
                </Form.Group>

                <Form.Group className="row mb-3" controlId="formBasicPassword">
                  <Col md>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Passord*"
                    >
                      <Form.Control
                        required
                        type="password"
                        placeholder=""
                        name="inputPassword"
                      />
                    </FloatingLabel>
                  </Col>

                  <Col md>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Bekreft passord*"
                    >
                      <Form.Control
                        required
                        type="password"
                        placeholder=""
                        name="inputConPassword"
                      />
                    </FloatingLabel>
                  </Col>
                </Form.Group>

                <p className="mb-3">
                  <i>
                    Ved å klikke på "Opprett profil"-knappen godtar du
                    EzyGo's &nbsp;
                    <u>
                      <NavLink to="/terms" className=" col-1">
                        <i> Vilkår og betingelser</i>
                      </NavLink>
                    </u>
                  </i>
                </p>

                {showAlert && <Alert variant={alertType}>{alertMsg}</Alert>}
                <Button variant="primary" type="submit">
                  Skape
                </Button>
                <br />
                <p className="py-2">
                  Eksisterende bruker, vennligst&nbsp;
                  <u>
                    <NavLink to="/login" className=" col-1">
                      <i>"Logg Inn"</i>
                    </NavLink>
                  </u>
                </p>
              </Form>
            </Col>
          </div>
        </section>
      )}
      {isMobile && (
        <section className="mission profilePages pt-5">
          <div className="d-flex justify-content-center align-items-center signup pt-5">
            <Col xs lg="9" className="logBox">
              <h2 className="display-5 mb-2">Opprett din profil</h2>
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="row mb-3" controlId="inputFirstName">
                  <Col md>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Fornavn*"
                    >
                      <Form.Control
                        required
                        type="text"
                        placeholder="Fornavn"
                        name="inputFirstName"
                      />
                    </FloatingLabel>
                  </Col>

                  <Col md>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Etternavn*"
                    >
                      <Form.Control
                        required
                        type="text"
                        placeholder="Etternavn"
                        name="inputLastName"
                      />
                    </FloatingLabel>
                  </Col>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FloatingLabel controlId="floatingInputGrid" label="E-post*">
                    <Form.Control
                      required
                      type="email"
                      placeholder="name@example.com"
                      name="inputEmail"
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group className="row mb-3" controlId="formBasicEmail">
                  <Col className="d-flex">
                    <Form.Select
                      style={{ width: "25%" }}
                      aria-label="Velg landskode"
                      name="inputCountryCode"
                    >
                      <option value="47">+47</option>
                      <option value="91">+91</option>
                    </Form.Select>
                    <FloatingLabel
                      style={{ width: "100%" }}
                      controlId="floatingInputGrid"
                      label="Telefonnummer*"
                    >
                      <Form.Control
                        required
                        type="number"
                        placeholder="Telefonnummer"
                        name="inputPhone"
                      />
                    </FloatingLabel>
                  </Col>
                  <Col md>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Henvisningskode (hvis noen)"
                    >
                      <Form.Control
                        defaultValue={refCode}
                        type="text"
                        placeholder="Referral Code"
                        name="inputReferral"
                      />
                    </FloatingLabel>
                  </Col>
                </Form.Group>

                <Form.Group className="row mb-3" controlId="formBasicPassword">
                  <Col md>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Passord*"
                    >
                      <Form.Control
                        required
                        type="password"
                        placeholder=""
                        name="inputPassword"
                      />
                    </FloatingLabel>
                  </Col>

                  <Col md>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Bekreft passord*"
                    >
                      <Form.Control
                        required
                        type="password"
                        placeholder=""
                        name="inputConPassword"
                      />
                    </FloatingLabel>
                  </Col>
                </Form.Group>

                <p className="mb-3">
                  <i>
                    Ved å klikke på "Opprett profil"-knappen godtar du
                    EzyGo's &nbsp;
                    <u>
                      <NavLink to="/terms" className=" col-1">
                        <i> Vilkår og betingelser</i>
                      </NavLink>
                    </u>
                  </i>
                </p>

                {showAlert && <Alert variant={alertType}>{alertMsg}</Alert>}
                <Button variant="primary" type="submit">
                  Skape
                </Button>
                <br />
                <p className="py-2">
                  Eksisterende bruker, vennligst&nbsp;
                  <u>
                    <NavLink to="/login" className=" col-1">
                      <i>"Logg Inn"</i>
                    </NavLink>
                  </u>
                </p>
              </Form>
            </Col>
          </div>
        </section>
      )}

      {isMobile && <MobileNavBottom />}
      {!isMobile && <BottomFooter />}
    </>
  );
}
