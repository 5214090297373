import { TextField, Button,Box } from "@mui/material";
import React,{ useState } from "react";
import "./form.css";
import logo from "../../assets/logo.png";
import axios from "axios";
import { apiUrl } from "../../helper/endpoint";

export default function SupportFrom() {
  const [input, setInput] = useState({
    name: "",
    phone: "",
    email: "",
    message: ""
  });

  let handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const submitform = async (e) => {
    axios.post(`${apiUrl}/api/v1/treeDrive/treedriveemail/contactForm`, input);
  };

  return (
    <Box
      id="boxSupport"
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: "25ch" }
      }}
      noValidate
      autoComplete="off"
    >
      <img className="supportLogo py-4" src={logo} />
      <TextField
        value={input.name}
        onChange={(e) => handleChange(e)}
        name="name"
        label="Name"
        variant="outlined"
      />
      <TextField
        value={input.phone}
        onChange={(e) => handleChange(e)}
        name="phone"
        label="Phone No"
        variant="outlined"
      />
      <TextField
        value={input.email}
        onChange={(e) => handleChange(e)}
        name="email"
        label="Email"
        variant="outlined"
      />
      <TextField
        value={input.message}
        onChange={(e) => handleChange(e)}
        name="message"
        label="Message"
        variant="outlined"
      />

      <Button className="py-4" variant="contained" onClick={() => submitform()}>
        Send
      </Button>
    </Box>
  );
}
