import { createSlice } from "@reduxjs/toolkit";
import { revertCou } from "../actions/rootaction";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

let initialState = {
  couponId: "",
  paymentDone: false,
  couponValue: 0,
  isFetched: false,
  appliedCouponCode: "",
  appliedCouponId: ""
};

let couponSlices = createSlice({
  name: "couponSlices",
  initialState,
  extraReducers: (builder) => {
    return builder.addCase(revertCou, () => {
      storage.removeItem("persist:root");

      return initialState;
    });
  },
  reducers: {
    saveCoupon: (state, action) => {
      state.couponId = action.payload;
    },

    removeOrderId: (state, action) => {
      state.couponId = "";
    },
    updateCouponReducer: (state, action) => {
      state.paymentDone = action.payload;
    },

    updateCouponValue: (state, action) => {
      state.couponValue = action.payload.cpValue;
      state.appliedCouponCode = action.payload.cpCode;
      state.isFetched = true;
    },

    resetFetchedState: (state, action) => {
      state.isFetched = false;
    }
  }
});

export const couponAction = couponSlices.actions;
export default couponSlices.reducer;
