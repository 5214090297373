import React from "react";
import { Row, Col, Form, Input, Select } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const MainDetail = (props) => {
  let user = useSelector((state) => state.userReducer);
  let customer = useSelector((state) => state.customerReducer);

  const [form] = Form.useForm();
  let [firstName, setFirstName] = useState("");
  let [lastName, setlastName] = useState("");
  let [phoneNo, setPhoneNo] = useState("");
  let [countryCode, setCountryCode] = useState("47");
  let [email, setEmail] = useState("");
  const onFinish = (values) => {};
  const { Option } = Select;
  const onChangeEvent = (event) => {
    if (event.target.name == "firstName") {
      setFirstName(event.target.value);
    }
    if (event.target.name == "lastName") {
      setlastName(event.target.value);
    }
    if (event.target.name == "email") {
      setEmail(event.target.value);
    }
    if (event.target.name == "phoneNo") {
      if (event.target.value.length <= 10) {
        if (!isNaN(event.target.value)) {
          setPhoneNo(event.target.value);
        }
      }
    }
  };

  //Set initial Form values
  useEffect(() => {
    if (user._id != "") {
      form.setFieldsValue({
        firstname: user.firstName,
        lastname: user.lastName,
        email: user.email,
        phoneNo: user.phone
      });
      setFirstName(user.firstName);
      setlastName(user.lastName);
      setEmail(user.email);
      setPhoneNo(user.phone);
    }
    if (user._id == "" && customer._id != "") {
      form.setFieldsValue({
        firstname: customer.firstName,
        lastname: customer.lastName,
        email: customer.email,
        phoneNo: customer.phone
      });
      setFirstName(customer.firstName);
      setlastName(customer.lastName);
      setEmail(customer.email);
      setPhoneNo(customer.phone);
    }
  }, [user.firstName, user.lastName, user.email, user.phone]);

  useEffect(() => {
    if (props.formSet) {
      setFirstName("");
      setlastName("");
      setEmail("");
    }
  }, [props.formSet]);

  useEffect(() => {
    if (
      lastName.trim() != "" &&
      firstName.trim() != "" &&
      email.includes("@") &&
      phoneNo.toString().trim() != ""
    ) {
      props.setFormValid(
        true,
        lastName,
        firstName,
        email,
        phoneNo,
        countryCode
      );
    } else {
      props.setFormValid(
        false,
        lastName,
        firstName,
        email,
        phoneNo,
        countryCode
      );
    }
  }, [lastName, firstName, email, phoneNo, countryCode]);

  const onChangeCountryCode = (event) => {
    setCountryCode(event);
  };
  const onFinishFailed = (errorInfo) => {};
  return (
      <Form
        form={form}
        className="mainForm checkoutForm p-3"
        name="basic"
        initialValues={{
          remember: true
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row className="justify-content-between">
          <Col xs={24} md={11}>
            <Form.Item
              label="Fornavn"
              name="firstname"
              className="formField d-flex flex-column"
              rules={[
                {
                  required: true,
                  message: "Please input your Fornavn!"
                }
              ]}
            >
              <Input
                value={firstName}
                onChange={onChangeEvent}
                name="firstName"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={11}>
            <Form.Item
              label="Etternavn"
              name="lastname"
              className="formField d-flex flex-column"
              rules={[
                {
                  required: true,
                  message: "Please input your Etternavn!"
                }
              ]}
            >
              <Input
                value={lastName}
                onChange={onChangeEvent}
                name="lastName"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={11}>
            <Form.Item
              name="email"
              label="E-Mail"
              className="formField d-flex flex-column"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input your E-Mail!"
                }
              ]}
            >
              <Input name="email" value={email} onChange={onChangeEvent} />
            </Form.Item>
          </Col>
          <Col xs={24} md={11}>
            <Form.Item
              label="Telefonnr"
              name="phoneNo"
              className="formField d-flex flex-column"
            >
              <Input.Group compact>
                <Select
                  style={{ width: "30%" }}
                  placeholder="+47"
                  onChange={onChangeCountryCode}
                >
                  <Option value="47">+47</Option>
                  <Option value="91">+91</Option>
                </Select>
                <Input
                  min="0"
                  style={{ width: "70%" }}
                  name="phoneNo"
                  type="number"
                  value={phoneNo}
                  onChange={onChangeEvent}
                />
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
  );
};

export default MainDetail;