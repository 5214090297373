import { Layout } from "antd";
import { Outlet } from "react-router";

const { Header, Content } = Layout;

const ContentComponent = () => {
  return (
    <Content
      className="site-layout"
      style={{
        padding: "0px"
      }}
    >
      <div
        className="site-layout-background" >
        <div className="siteBg">
          <Outlet></Outlet>
        </div>
      </div>
    </Content>
  );
};
export default ContentComponent;
