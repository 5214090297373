import React, { useState } from "react";
import { Row, Col, Button, Modal } from "antd";
import CartModal from "./CartModal";
const AddToCart = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleCartModalClose = () => {
    setIsModalVisible(false);
  };
  return (
    <div className="site-card-border-less-wrapper">
      <Row>
        <Button
          type="primary"
          onClick={showModal}
          block
          className="checkoutBtn"
        >
          Add to Cart
        </Button>
        <Modal
          title="Lorem ipsum"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Row>
            <Col span={24}>
              <CartModal handleCartModalClose={handleCartModalClose} />
            </Col>
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Col span={6}>
              <p className="itemName">Cheese Pizza</p>
            </Col>
            <Col span={6}>
              <p className="itemName">X3</p>
            </Col>
            <Col span={6}>
              <p className="itemName">Kr. 3</p>
            </Col>
            <Col span={6}>
              <Button type="primary" className="saveBtn">
                Remove
              </Button>
            </Col>
            <hr />
          </Row>
          <Row>
            <Col span={12}>
              <p className="itemName">Cheese Pizza</p>
            </Col>
            <Col span={12} className="productTotalContainer">
              <p className="itemName">Kr. 2.56</p>
            </Col>
          </Row>
          <br />

          <Row>
            <Col span={12}>
              <p className="itemName">Cheese Pizza</p>
            </Col>
            <Col span={12} className="productTotalContainer">
              <p className="itemName">Kr. 2.16</p>
            </Col>
          </Row>

          <br />
          <Row>
            <Col span={12}>
              <p className="itemTotal">Total(3 items)</p>
            </Col>
            <Col span={12} className="productTotalContainer">
              <p className="itemTotal">Kr. 4.72</p>
            </Col>
          </Row>
        </Modal>
      </Row>
    </div>
  );
};
export default AddToCart;
