import { createSlice } from "@reduxjs/toolkit";
import { revertAll, revertProduct, revertCus } from "../actions/rootaction";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

let initialState = {
  _id: "",
  email: "",
  phone: "",
  lastName: "",
  firstName: "",
  countryCode: ""
};
let customerSlice = createSlice({
  name: "Customer",
  initialState,
  extraReducers: (builder) => {
    return builder.addCase(revertCus, () => {
      storage.removeItem("persist:root");

      return initialState;
    });
  },
  reducers: {
    saveCustomer: (state, action) => {
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.lastName = action.payload.lastName;
      state.firstName = action.payload.firstName;
      state.countryCode = action.payload.countryCode;
      state._id = action.payload.id;
    },
    removeCustomer: (state, action) => {
      state.email = "";
      state.phone = "";
      state.lastName = "";
      state.firstName = "";
      state.countryCode = "";
      state._id = "";
    }
  }
});

export const customerAction = customerSlice.actions;

export default customerSlice.reducer;
