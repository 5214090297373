import { createAction } from "@reduxjs/toolkit";
export const revertAll = createAction("REVERT_ALL");
export const revertCart = createAction("REVERT_CART");
export const revertRestaurant = createAction("REVERT_RES");
export const revertRetailer = createAction("REVERT_RET");
export const revertLocation = createAction("REVERT_LOC");
export const revertDis = createAction("REVERT_DIS");
export const revertProduct = createAction("REVERT_PROD");
export const revertRoot = createAction("REVERT_ROOT");
export const revertCus = createAction("REVERT_CUS");
export const revertCou = createAction("REVERT_COU");
