import { createSlice } from "@reduxjs/toolkit";
import { revertAll, revertRetailer } from "../actions/rootaction";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

let initialState = {
  retailer: [],
  reserror: { isError: false, resMessage: "" },
  selectedRetailer: null
};
let retailerSlice = createSlice({
  name: "retailer",
  initialState,
  extraReducers: (builder) => {
    return builder.addCase(revertRetailer, () => {
      storage.removeItem("persist:root");

      return initialState;
    });
  },
  reducers: {
    saveRetailer: (state, action) => {
      state.retailer = action.payload;
      if (action.payload.length > 0) {
        state.reserror.isError = false;
        state.reserror.resMessage = "";
      } else {
        state.reserror.isError = true;
        state.reserror.resMessage = action.payload;
      }
    },
    setError: (state, action) => {
      state.reserror.isError = true;
      state.reserror.resMessage = action.payload;
    },
    saveSelectedRetailer: (state, action) => {
      state.selectedRetailer = action.payload;
    },
    removeSelectedRetailer: (state, action) => {
      state.selectedRetailer = "";
    },
    removeRetailer: (state, action) => {
      state.retailer = "";
    }
  }
});

export const retailerAction = retailerSlice.actions;
export const retailerReducer = retailerSlice.reducer;
