import React, { useEffect, useState } from "react";
import { Col, Row, Button, Modal, Checkbox, Input } from "antd";
import "../../../../../topbar.css";
import { cartAction } from "../../../../../../../store/slices/cartslices";
import ExtraProducts from "../../../../ExtraProducts/ExtraProducts";
import { s3url } from "../../../../../../../helper/endpoint";
import { useDispatch, useSelector } from "react-redux";
import getDiscount from "../../../../../../../helper/discountHelper";

const { TextArea } = Input;
const ConfirmProductModal = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [superCategoryState, setsuperCategory] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [ingOptions, setingOptions] = useState([]);
  const [instruction, setInstruction] = useState([]);
  const [instructionArray, setInstructionArray] = useState([]);
  const [instructionIds, setInstructionIds] = useState([]);
  const [onMildChecked, setOnMildCheck] = useState(false);
  const [onSpicyChecked, setonSpicyChecked] = useState(false);
  const [onExtraSpicyChecked, setonExtraSpicyChecked] = useState(false);
  const [extraItems, setExtraItems] = useState([]);
  const [extraInstructions, setextraInstructions] = useState("");
  const [price, setprice] = useState(0);
  const [extraPrice, setExtraPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);

  const [err_msg, seterr_msg] = useState("");

  const [disable, setDisable] = React.useState(false);

  const [ing, setIng] = useState([]);
  let superCategory = useSelector(
    (state) => state.productReducer.superCategory
  );
  let restaurant = useSelector(
    (state) => state.restaurantReducer.selectedRestaurant
  );

  let res = useSelector((state) => state.restaurantReducer.selectedRestaurant);
  let root = useSelector((state) => state.rootReducer);

  let cart = useSelector((state) => state.cartReducer);
  let dispatch = useDispatch();
  const showModal = () => {
    setIsModalVisible(true);
  };
  useEffect(() => {
    if (superCategory && superCategory.length > 0) {
      setsuperCategory(
        superCategory.filter((sin) => {
          return sin.isShowAsExtra;
        })
      );
    }
  }, [superCategory]);
  const handleOk = () => {
    setIsModalVisible(false);

    props.closeModal();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    props.closeModal();
  };
  const onChange = (event, sin) => {
    if (event.target.checked) {
      setInstructionIds((stateIns) => {
        return stateIns.filter((stateIns) => {
          return stateIns != sin.id;
        });
      });
      setInstructionArray((stateIns) => {
        return stateIns.filter((stateIns) => {
          return stateIns != `Uten ${sin.label}`;
        });
      });
    } else {
      setInstructionArray((insState) => {
        return [...insState, `Uten ${sin.label}`];
      });
      setInstructionIds((insState) => {
        return [...insState, sin.id];
      });
    }
  };

  const ExtraInsoptions = [
    {
      label: "Mild",
      value: "Mild"
    },
    {
      label: "Medium",
      value: "Medium"
    },
    {
      label: "Strong",
      value: "Strong"
    }
  ];

  let onChangeExtra = (event, type) => {
    if (event.target.checked) {
      if (type == "Mild") {
        setextraInstructions(type);
        setonExtraSpicyChecked(false);
        setonSpicyChecked(false);
        setOnMildCheck(true);
      }
      if (type == "Strong") {
        setextraInstructions(type);
        setOnMildCheck(false);
        setonSpicyChecked(false);
        setonExtraSpicyChecked(true);
      }
      if (type == "Medium") {
        setextraInstructions(type);
        setOnMildCheck(false);
        setonSpicyChecked(true);
        setonExtraSpicyChecked(false);
      }
    } else {
      setonExtraSpicyChecked(false);
      setonSpicyChecked(false);
      setOnMildCheck(false);
      setextraInstructions("");
    }
  };

  const onAddExtraItem = (checked, extraItem) => {
    let { image, name, price, _id, ingredents, incPrice } = extraItem;
    let singleItem = {
      image,
      name,
      price,
      _id,
      ingredents,
      incPrice
    };
    if (checked) {
      setExtraItems((sin) => {
        return [...sin, singleItem];
      });

      setprice((price) => price + Number(singleItem.incPrice));
      setExtraPrice((price) => price + Number(singleItem.incPrice));
    } else {
      setExtraItems((sin) => {
        let arr = sin.filter((singF) => {
          return singF._id != singleItem._id;
        });
        return arr;
      });
      setprice((price) => price - Number(singleItem.incPrice));
      setExtraPrice((price) => price - Number(singleItem.incPrice));
    }
  };

  useEffect(() => {
    let options = props.ing.map((sinIng) => {
      return { label: sinIng.name, value: sinIng.name, id: sinIng.ingId };
    });
    setIng(options);

    setprice(+props.singleProduct.incPrice);
    setingOptions(options);
  }, [props.ing, props.singleProduct.incPrice]);

  useEffect(() => {}, [instructionArray]);

  useEffect(() => {
    let { discountCalculated, discountPrice } = getDiscount(
      cart.totalPrice + price,
      restaurant,
      root.orderType
    );

    setDiscountPercent(discountCalculated);
    setDiscountPrice(discountPrice);
  }, [cart, restaurant, price]);

  const onAddToCartHandler = () => {
    let ItemData = {
      ...props.singleProduct,
      extraItems: extraItems,
      extraPrice: extraPrice,
      instruction: instructionArray,
      extraInstructions: extraInstructions,
      totalPrice: price,
      quantity: quantity,
      discountCalculated: discountPercent,
      discountPrice: discountPrice,
      instructionIds: instructionIds
    };

    dispatch(cartAction.addToCart(ItemData));
    if (props.isMobile) {
      props.onCloseMobileProductModal();
    }
    handleOk();
  };
  useEffect(() => {
  }, [extraItems]);

  let onInstructionChange = (event) => {
    setInstructionArray((insState) => event.target.value.split(","));
  };
  // method for plus and minus product  ---> by sid sir
  const add_quantity_price = (add) => {
    if (add) {
      if (quantity < 50) {
        setQuantity((quantity) => {
          return quantity >= 50 ? 50 : quantity + 1;
        });
        setprice(
          (price) => Number(price) + Number(props.singleProduct.incPrice)
        );
      } else {
        seterr_msg("Maximum 50 allowed");
      }
    } else {
      if (quantity > 1) {
        setQuantity((quantity) => {
          return quantity <= 1 ? 1 : quantity - 1;
        });

        setprice((price) => price - props.singleProduct.incPrice);

        seterr_msg((err_msg) => {
          if (quantity < 50) {
            err_msg = "";
          }
        });
      }
    }
  };
  let productName = () => {
    return props.isMobile
      ? props.singleProduct.name
      : props.singleProduct.name + " Kr." + props.singleProduct.incPrice;
  };
  return (
    <Modal
      style={{ zIndex: 9999999 }}
      title={productName()}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
    >
      <Row className="justify-content-between">
        <Col
          span={7}
          className="boxShadowMobile d-flex flex-column justify-content-center align-items-center"
        >
          <img
            src={`${s3url}/${props.singleProduct.thumbnail}`}
            style={{ width: "100px" }}
          />
          <div className="mt-3 d-flex align-items-center justify-content-center text-center">
            {props.isMobile && (
              <>
                {props.singleProduct.showOffer && (
                  <h4
                    className="mb-0 d-flex"
                    style={{
                      textDecorationLine: "line-through",
                      padding: "0px 20px"
                    }}
                  >
                    ({"Kr. " + props.singleProduct.price})
                  </h4>
                )}

                <h4 className="productName mb-0">
                  ({"Kr. " + props.singleProduct.incPrice})
                </h4>
              </>
            )}
          </div>
        </Col>
        <Col span={16}>
          <Row>
            <Col span={12} className="d-flex justify-content-between">
              {ingOptions == "" ? (
                ""
              ) : (
                <div>
                  <p style={{ color: "#686B78", fontWeight: "bold" }}>
                    Ingredienser
                  </p>
                  {ingOptions.map((sin, index) => {
                    return (
                      <div key={index}>
                        <Checkbox
                          onChange={(event) => onChange(event, sin)}
                          defaultChecked
                        >
                          {sin.value}
                        </Checkbox>
                      </div>
                    );
                  })}
                </div>
              )}
            </Col>
            <Col span={12} className="d-flex justify-content-between">
              {" "}
              {props.singleProduct.isExtraInformation && (
                <div>
                  <p style={{ color: "#686B78", fontWeight: "bold" }}>
                    Spiciness
                  </p>
                  <div>
                    <Checkbox
                      onChange={(event) => onChangeExtra(event, "Mild")}
                      checked={onMildChecked}
                    >
                      Mild
                    </Checkbox>
                  </div>
                  <div>
                    <Checkbox
                      onChange={(event) => onChangeExtra(event, "Medium")}
                      checked={onSpicyChecked}
                    >
                      Medium
                    </Checkbox>
                  </div>
                  <div>
                    <Checkbox
                      onChange={(event) => onChangeExtra(event, "Strong")}
                      checked={onExtraSpicyChecked}
                    >
                      Strong
                    </Checkbox>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="py-3">
        <Col span={24}>
          {props.singleProduct.ingredentsDesc != "" ? (
            <p style={{ color: "#686B78", fontWeight: "bold" }}>
              {props.isMobile && (
                <p>
                  Allergener :<span>{props.singleProduct.ingredentsDesc}</span>{" "}
                </p>
              )}
            </p>
          ) : (
            ""
          )}

          <p style={{ color: "#686B78", fontWeight: "bold" }} className="pb-2">
            Legg til kommentar (valgfritt):
          </p>
          <TextArea
            className="w-100"
            placeholder=""
            rows={2}
            value={instructionArray.join(",")}
            onChange={onInstructionChange}
          />
        </Col>
      </Row>
      <Row>
        <p style={{ color: "#686B78", fontWeight: "bold" }}>Extras:</p>
      </Row>
      <Row>
        <Col span={24}>
          <ExtraProducts
            onAddExtraItem={onAddExtraItem}
            superCategory={superCategoryState}
          />
        </Col>
      </Row>
      {res.availability.status === true ? (
        <Row className="d-flex justify-content-between pt-3">
          <Col span={6} className="increBtn">
            <span
              className="minus"
              onClick={() => {
                add_quantity_price(false);
              }}
            >
              -
            </span>
            <p className="px-3">{quantity}</p>
            <span
              className="plus"
              disabled={disable}
              onClick={() => {
                add_quantity_price(true);
                setDisable(true);
              }}
            >
              +
            </span>
          </Col>
          <Col span={14} className="m-0">
            <Button
              type="primary"
              block
              className="checkoutBtn"
              onClick={onAddToCartHandler}
            >
              Legg til {price.toFixed(2)}
            </Button>
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row className="p-2">
        <i>
          <p style={{ fontSize: "14px" }}>{err_msg}</p>
        </i>
      </Row>
    </Modal>
  );
};
export default ConfirmProductModal;
