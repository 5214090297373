import locationReducer from "./slices/locationslices";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { restaurantReducer } from "./slices/restaurantslices";
import { retailerReducer } from "./slices/retailerslice";
import productReducer from "./slices/productslices";
import cartReducer from "./slices/cartslices";
import distanceReducer from "./slices/distanceSlices";
import rootReducer from "./slices/rootSlices";
import errorReducer from "./slices/rootSlices";
import customerReducer from "./slices/customerslices";
import couponReducer from "./slices/couponslices";

import userReducer from "./slices/userslices";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["errorReducer"] // navigation will not be persisted
};
let rootReducers = combineReducers({
  locationReducer: locationReducer,
  restaurantReducer,
  retailerReducer,
  productReducer,
  cartReducer,
  distanceReducer,
  rootReducer,
  errorReducer,
  couponReducer,
  customerReducer,
  userReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export default store;
export const persistor = persistStore(store);
