import {Checkbox, Collapse } from "antd";
import { useSelector } from "react-redux";
import { s3url } from "../../../../helper/endpoint";
const { Panel } = Collapse;

const ExtraProducts = (props) => {
  const onChangeValue = (key) => {};

  let res = useSelector((state) => state.restaurantReducer.selectedRestaurant);
  const onChange1 = (e, prod) => {
    props.onAddExtraItem(e.target.checked, prod);
  };
  let getIncreasedPrice = (price) => {
    let incPrice = +price;
    if (res.tdExtraPriceIncrease) {
      incPrice = incPrice + (+res.tdExtraPriceIncrease / 100) * incPrice;
    }
    return incPrice;
  };

  return (
    <Collapse accordion defaultActiveKey={["1"]} onChange={onChangeValue}>
      {props.superCategory.map((sinSp) => {
        return (
          <Panel header={sinSp.name} key={sinSp._id}>
            {sinSp.products && sinSp.products.length
              ? sinSp.products.map((sinProd) => {
                  return (
                    <div style={{ margin: "0.5rem 0" }} key={sinProd._id}>
                      <Checkbox
                        onChange={(event) =>
                          onChange1(event, {
                            ...sinProd,
                            incPrice: getIncreasedPrice(sinProd.price)
                          })
                        }
                        key={sinProd._id}
                      >
                        <img
                          src={`${s3url}/${sinProd.image}`}
                          style={{ width: "40px" }}
                        />
                        &nbsp;&nbsp; {sinProd.name} ({"Kr. "}
                        {getIncreasedPrice(sinProd.price)})
                      </Checkbox>
                    </div>
                  );
                })
              : ""}
          </Panel>
        );
      })}
    </Collapse>
  );
};
export default ExtraProducts;
