import React from "react";
import { Card, Row, Col, Button, Form, Input, Select } from "antd";
export const Pickup = () => {
  const onFinish = (values) => {};

  const onFinishFailed = (errorInfo) => {};
  return (
    <>
      <div className="formWrapper">
          <Row>
            <Col span={14}>
              <div className="site-card-border-less-wrapper">
                <Card
                  bordered={false}
                  className="cardContainer"
                >
                  <Col className="formHeader">Customer information</Col>
                  <div className="formInfo">
                    <Form
                      className="mainForm"
                      name="basic"
                      labelCol={{
                        span: 8
                      }}
                      wrapperCol={{
                        span: 24
                      }}
                      initialValues={{
                        remember: true
                      }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Firstname"
                        name="firstname"
                        className="formField"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Firstname!"
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Lastname"
                        name="lastname"
                        className="formField"
                        rules={[
                          {
                            required: true,
                            message: "Please input your lastname!"
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Form>
                    <Form
                      className="mainForm"
                      name="basic"
                      labelCol={{
                        span: 8
                      }}
                      wrapperCol={{
                        span: 24
                      }}
                      initialValues={{
                        remember: true
                      }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                    >
                      <Form.Item
                        name={["user", "email"]}
                        label="Email"
                        className="formField"
                        rules={[
                          {
                            type: "email",
                            required: true,
                            message: "Please input your email!"
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Country Code"
                        className="formField"
                        style={{ maxWidth: "100%" }}
                      >
                        <Select>
                          <Select.Option value="12345">12345</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={["user", "phone"]}
                        label="Phone"
                        className="formField"
                        rules={[
                          {
                            required: true,
                            message: "Please input your phone!"
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Form>

                    <Row className="formFooter">
                      <Col className="saveContainer" span={12}>
                        <Button type="primary" className="saveBtn">
                          Lagre
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
            </Col>
            <Col
              span={10}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div className="site-card-border-less-wrapper">
                <Card
                  bordered={false}
                  className="formCard1"
                >
                  <div className="cardPayment">
                    <Col className="formHeader">Innbetaling</Col>
                    <Col className="payCard">
                      <Button type="primary" className="saveBtn" block>
                        Click here for Payment
                      </Button>
                    </Col>
                    <Row style={{ padding: "10px" }}>
                      <Col span={6}>
                        <p className="itemName">Cheese Pizza</p>
                      </Col>
                      <Col span={6}>
                        <p className="itemName">X3</p>
                      </Col>
                      <Col span={6}>
                        <p className="itemName">Kr. 3</p>
                      </Col>
                      <Col span={6}>
                        <Button type="primary" className="saveBtn">
                          Remove
                        </Button>
                      </Col>
                      <hr />
                    </Row>
                    <Row style={{ padding: "10px" }}>
                      <Col span={12}>
                        <p className="itemName">Cheese Pizza</p>
                      </Col>
                      <Col
                        span={12}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <p className="itemName">Kr. 2.56</p>
                      </Col>
                    </Row>
                    <br />

                    <Row style={{ padding: "10px" }}>
                      <Col span={12}>
                        <p className="itemName">Cheese Pizza</p>
                      </Col>
                      <Col
                        span={12}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <p className="itemName">Kr. 2.16</p>
                      </Col>
                    </Row>

                    <br />
                    <Row style={{ padding: "10px" }}>
                      <Col span={12}>
                        <p className="itemTotal">Total(2 Items)</p>
                      </Col>
                      <Col
                        span={12}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <p className="itemTotal">Kr. 4.72</p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        {/* </div> */}
      </div>
    </>
  );
};
