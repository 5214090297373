const savingOriginAndDestnationDistance = (response) => {
  let distance = 0;
  let resLat = 0;
  let resLng = 0;
  let cusLat = 0;
  let cusLng = 0;
  if (response) {
    if (
      response.routes.length &&
      response.routes[0].legs &&
      response.routes[0].legs.length
    ) {
      
      distance = response.routes[0].legs[0].distance.text.split(" ")[0];
      if (distance.includes(",")) {
        distance = distance.toString().replace(",", ".");
      }
    }

    if (
      response.request &&
      response.request.origin &&
      response.request.origin.location
    ) {
      resLat = response.request.origin.location.lat();
      resLng = response.request.origin.location.lng();
    }

    if (
      response.request &&
      response.request.destination &&
      response.request.destination.location
    ) {
      cusLat = response.request.destination.location.lat();
      cusLng = response.request.destination.location.lng();
    }
  }


  return {
    resLat: resLat,
    cusLat: cusLat,
    resLng: resLng,
    cusLng: cusLng,
    disRestToCus: distance
  };
};
export default savingOriginAndDestnationDistance;
