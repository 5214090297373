import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import ListGroup from "react-bootstrap/ListGroup";
import { useParams } from "react-router-dom";
import { apiUrl } from "../../helper/endpoint";
import axios from "axios";
import { s3url } from "../../helper/endpoint";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Card, Modal, notification, Descriptions, Collapse } from "antd";
import { isMobile } from "react-device-detect";
import offerIcon from "../../assets/offerTag.png";
import "./vendor.css";

export const VendorsProduct = (props) => {
  const { Panel } = Collapse;
  const slug = useParams();
  let [data, setData] = useState([]);
  const [price, setPrice] = useState(0);
  const [orignalPrice, setOrignalPrice] = useState(0);
  const [showOffer, setShowOffer] = useState(false);
  let [productData, setProductData] = useState([]);
  let [singleCatName, setSingleCatName] = useState();
  const [totalOfProducts, setTotalOfProducts] = useState(0);
  const [totalTax, setTotalTax] = useState();
  let [singleProduct, setSingleProduct] = useState();
  let [cartData, setCartData] = useState([]);
  let [availQuantity, setAvailQuantity] = useState();
  const [orderPlaced, setOrderPlaced] = useState(false);
  let [isProductModalVb, setIsProductModalVisible] = useState(false);
  let dispatch = useDispatch();
  const [cartState, setCartState] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [mvaArray, setMvaArray] = useState([]);
  const cartSize = props;
  const [delProduct, setDelProduct] = useState("");
  let cart = localStorage.getItem("cartData");
  let cityDriveOut = localStorage.getItem("cityDriveOut");
  let [onOpenProductMobileModal, setOnOpenMobileProductModal] = useState(false);
  const navigate = useNavigate();
  let [productModal, setProductModal] = useState(false);
  const vendorId = localStorage.vid;
  let createOrder = async () => {};
  const { Meta } = Card;
  const subTotal = totalOfProducts - totalTax;

  useEffect(() => {
    if (cartData.length) {
      localStorage.setItem("cartData", JSON.stringify(cartData));
    }

    localStorage.setItem("vendorOrderType", "Take Away");
  }, [cartData]);

  if (!cartData.length && cart.length) {
    setCartData(JSON.parse(cart));
  }
  useEffect(() => {
    async function fetchData() {
      let payloadData = {
        branchId: slug.id,
        type: "id"
      };
      try {
        const response = await axios.post(
          `${apiUrl}/api/v1/vendor/vendorProducts`,
          payloadData
        );
        const resData = response.data.data;
        setData(resData);
        const prodData = resData[0].products;
        const prodName = resData[0].products[0].name;
        setProductData(prodData);
        setSingleCatName(prodName);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [isLoading]);

  useEffect(() => {
    productTotal();
    caltotalTax();
    mvaPercentage();
  }, [cartData, totalOfProducts]);

  let onClickSuperCategoryHandler = (superCat) => {
    let products = superCat.products.length > 0 ? superCat.products : [];
    let singlePro = products.length > 0 ? products[0] : null;
    setProductData(products);
    setSingleCatName(superCat.name);
    setSingleProduct(singlePro);
  };

  const ToProcessOrder = () => {
    setOrderPlaced(false);
    let items = [];
    cartData.map((e) => {
      let item = {};
      item.id = e.id;
      item.name = e.productName;
      item.quantity = e.quantity;
      item.unitType = e.unitType;
      item.availQuantity = e.availQuantity;
      items.push(item);
    });

    // send payload
    let postData = {
      items,
      branchId: vendorId
    };
    createOrder(postData);

    if (cartData.length) {
      navigate("/VendorsCheckout", {
        state: {
          cartData: cartData,
          total: totalOfProducts,
          cartState: cartState,
          mvaArray: mvaArray,
          orderTotal: subTotal.toFixed(2)
        }
      });
    } else {
      notification.open({
        message: "Please add items in cart",
        style: { top: "-50px" },
        duration: 2
      });
    }
    console.log("in here");
  };

  let addToCart = (productInfo, productAction) => {
    if (productInfo.quantity < 2 && productAction == "minus") {
      handleShow(productInfo);
      // deleteData(productInfo.id);
      return;
    }
    //cart's updating\
    let availQuantity = Number(productInfo.availQuantity);
    // let thisItem = cartData.filter((e) => e.id === productInfo.id);
    let thisItem = cartData.filter((e) => e.posId === productInfo.posId);

    if (thisItem.length > 0) {
      //now update the item in cart
      let updateItem = cartData.map((e) => {
        // if (productInfo.id == e.id) {
        if (productInfo.posId == e.posId) {
          let nQuantity;
          if (productAction == "add") {
            nQuantity = e.quantity + 1;
          } else if (productAction == "minus") {
            nQuantity = e.quantity - 1;
          }
          let nPrice;
          if (productInfo.prodHasOffer) {
            nPrice = e.offerPrice * nQuantity * availQuantity;
          } else {
            nPrice = e.productPrice * nQuantity * availQuantity;
          }
          return {
            ...e,
            quantity: nQuantity,
            totalAmount: nPrice
          };
        }
        return e;
      });
      setCartData(updateItem);
    } else {
      //no item found in cart so insert this item in cart
      if (productInfo.prodHasOffer) {
        productInfo.totalAmount =
          productInfo.offerPrice * productInfo.availQuantity;
      } else {
        productInfo.totalAmount =
          productInfo.productPrice * productInfo.availQuantity;
      }
      setCartData((old) => [...old, productInfo]);
    }
  };
  const reversedCart = [...cartData].reverse().map((element) => {
    return element;
  });

  // dropdown action
  const dropdownAction = (e, product) => {
    let availQnty = e.target.value;
    let updatedQty = cartData.map((e) => {
      // if (e.id == product.id) {
      if (e.posId == product.posId) {
        let totalAmount;
        if (e.prodHasOffer) {
          totalAmount = e.offerPrice * e.quantity * availQnty;
        } else {
          totalAmount = e.productPrice * e.quantity * availQnty;
        }

        return {
          ...e,
          availQuantity: availQnty,
          totalAmount
        };
      }
      return e;
    });
    setCartData(updatedQty);
  };

  let productTotal = () => {
    let total = cartData.reduce(
      (oldValue, newTotal) =>
        newTotal.prodHasOffer
          ? (oldValue +=
              newTotal.quantity * newTotal.offerPrice * newTotal.availQuantity)
          : (oldValue +=
              newTotal.quantity *
              newTotal.productPrice *
              newTotal.availQuantity),
      0
    );
    setTotalOfProducts(total);
  };
  const mvaPercentage = () => {
    let taxArr = [];
    let mvaTotal = 0;
    cartData.map((e) => {
      let prdPrice;
      if (e.prodHasOffer) {
        prdPrice = e.quantity * e.offerPrice;
      } else {
        prdPrice = e.quantity * e.productPrice;
      }
      let prdMva = prdPrice - (100 * prdPrice) / (100 + e.prodTax);
      mvaTotal += prdMva;
      let taxArrObj = {
        tax: e.prodTax,
        taxTotal: prdMva
      };
      let taxFound = false;
      if (taxArr.length) {
        taxArr.map((taxObj) => {
          if (taxObj.tax == taxArrObj.tax) {
            taxObj.taxTotal = taxObj.taxTotal + taxArrObj.taxTotal;
            taxFound = true;
          }
        });
        if (!taxFound) {
          taxArr.push(taxArrObj);
        }
      } else {
        taxArr.push(taxArrObj);
      }
    });
    setMvaArray(taxArr);
  };
  const caltotalTax = () => {
    let totalMva = 0;
    cartData.map((e) => {
      let prdPrice;
      if (e.prodHasOffer) {
        prdPrice = e.quantity * e.offerPrice;
      } else {
        prdPrice = e.quantity * e.productPrice;
      }
      totalMva += prdPrice - (100 * prdPrice) / (100 + e.prodTax);
    });
    setTotalTax(totalMva);
  };

  useEffect(() => {
    if (cartData.length && !cartState) {
      localStorage.setItem("cartData", JSON.stringify(cartData));
    }
  }, [cartData]);

  if (!cartData.length && cart.length && !cartSize.length) {
    setCartData(JSON.parse(cart));
  }

  //------------------ check offer price   ---------------------//
  const checkOffer = (responseData) => {
    let isEnable = responseData.some((product) => {
      let price = product.price;
      let offer = product.offer;
      let offerEnable = product.offerEnable;
      let offerPrice = offer.offerPrice ? offer.offerPrice : price;
      let startDate = offer.startDate ? offer.startDate : price;
      let endDate = offer.endDate ? offer.endDate : price;
      let now = new Date();
      return (
        offerEnable && now <= new Date(endDate) && now >= new Date(startDate)
      );
    });
    return isEnable;
  };
  //------------------ check offer price end   ---------------------//

  // Deleted cart data on button click
  const deleteData = (id) => {
    // let deletedData = cartData.filter((e) => e.id != id);
    let deletedData = cartData.filter((e) => e.posId != id);
    console.log('---remove item---', deletedData)
    setCartData(deletedData);
    localStorage.setItem("cartData", deletedData);
    setConfirmShow(false);
    // if (deletedData.length == 0) {
    //   setCartData([]);
    //   setConfirmShow(false);
    // } else {
    //   setCartData(deletedData);
    //   setConfirmShow(false);
    // }
  };

  const clearCartPro = () => {
    setCartData([]);
    localStorage.setItem("cartData", []);
  };

  const handleShow = (e) => {
    console.log("---handle show", e);
    setConfirmShow(true);
    // setDelProduct(e.id);
    setDelProduct(e.posId);
  };

  const handleClose = () => {
    setConfirmShow(false);
  };

  const onClickProducts = (sinProd) => {
    if (isMobile) {
      setOnOpenMobileProductModal(true);
    }
    setSingleProduct(sinProd);
    setProductModal(true);
    setIsProductModalVisible(false);
  };

  const onCloseMobileProductModal = () => {
    setOnOpenMobileProductModal(false);
  };

  return (
    <Container className="productWrapper">
      <Row className="main vendor px-4 pt-3">
        <Col xs lg="3" className="col1 py-3 pl-3">
          <ListGroup as="ul" style={{ height: "100%" }}>
            {data.map((superCat) => {
              let catProducts = superCat.products;
              let isCatHasOffer = checkOffer(catProducts);
              return (
                <ListGroup.Item
                  as="li"
                  key={superCat.id}
                  onClick={() => {
                    onClickSuperCategoryHandler(superCat);
                  }}
                >
                  {superCat.name}
                  {isCatHasOffer ? (
                    <img src={offerIcon} alt="offer available" width={60} />
                  ) : (
                    " "
                  )}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Col>

        {/*-------mobile collapse code start-------*/}

        {isMobile && (
          <div
            style={{
              height: "90vh",
              overflow: "hidden",
              overflowY: "scroll"
            }}
          >
            <Col className="CollapseCategoryMobile">
              {data && data.length > 0
                ? data.map((superCat, index) => {
                    return (
                      <Collapse
                        accordion
                        bordered={false}
                        defaultActiveKey={["1"]}
                        className="site-collapse-custom-collapse"
                      >
                        <Panel
                          header={superCat.name}
                          key={index + 1}
                          className="site-collapse-custom-panel"
                        >
                          <Col xs lg="5" className="subCat p-3">
                            <ListGroup className="subCatList">
                              <h4 className="px-3 py-3 m-0 CatNameDisplay">
                                {superCat.name}
                                {console.log("superCat", superCat)}
                                {console.log("props", props)}
                              </h4>
                              <div className="subCatListUL">
                                {superCat.products.map((sinProd) => {
                                  let showOffer = false;

                                  if (sinProd.offerEnable) {
                                    let todayDate = new Date();
                                    let startDate = new Date(
                                      sinProd.offer.startDate
                                    );
                                    let endDate = new Date(
                                      sinProd.offer.endDate
                                    );

                                    if (
                                      todayDate >= startDate &&
                                      todayDate <= endDate
                                    ) {
                                      showOffer = true;
                                    }
                                  }

                                  return (
                                    <ListGroup.Item
                                      className="subCategory d-flex justify-content-between align-items-center"
                                      key={sinProd._id}
                                      onClick={() => {
                                        superCat.onClickProducts(sinProd);
                                      }}
                                    >
                                      <div className="subCategory d-flex justify-content-between align-items-center">
                                        <img
                                          src={`${s3url}/${sinProd.thumbnail}`}
                                          style={{ width: "40px" }}
                                        />
                                        <p className="productDes">
                                          {sinProd.name}
                                        </p>
                                      </div>

                                      {showOffer && (
                                        <img
                                          src={offerIcon}
                                          alt="offer available"
                                          width={70}
                                        />
                                      )}
                                      <Col
                                        span={1}
                                        className="d-flex justify-content-end align-items-center"
                                      >
                                        <button
                                          className="addToCart"
                                          onClick={() => {
                                            addToCart(
                                              {
                                                posId: new Date().getTime(),
                                                id: sinProd._id,
                                                productName: sinProd.name,
                                                productPrice: sinProd.price,
                                                quantity: 1,
                                                categoryId: sinProd.categoryId,
                                                status: "IN-PROCESS",
                                                prodTax: sinProd.prodTax,
                                                unitType: sinProd.unitType,
                                                availQuantityArr:
                                                  sinProd.availQuantity,
                                                availQuantity:
                                                  sinProd.availQuantity[0],
                                                offerPrice:
                                                  sinProd.offer.offerPrice,
                                                prodHasOffer:
                                                  sinProd.prodHasOffer
                                              },

                                              "add"
                                            );
                                          }}
                                        >
                                          Add
                                        </button>
                                      </Col>
                                    </ListGroup.Item>
                                  );
                                })}
                              </div>
                            </ListGroup>
                          </Col>
                        </Panel>
                      </Collapse>
                    );
                  })
                : ""}
            </Col>
          </div>
        )}

        {/*-------mobile collapse code end-------*/}
        {!isMobile && (
          <Col xs lg="5" className="subCat p-3">
            <ListGroup className="subCatList">
              <h4 className="px-3 py-3 m-0 CatNameDisplay">{singleCatName}</h4>
              <div className="subCatListUL">
                {productData.map((product) => {
                  if (product) {
                  }
                  let price = product.price;
                  let offer = product.offer;
                  let offerEnable = product.offerEnable;
                  let offerPrice = offer.offerPrice ? offer.offerPrice : price;
                  let startDate = offer.startDate ? offer.startDate : price;
                  let endDate = offer.endDate ? offer.endDate : price;
                  let now = new Date();
                  let isofferValid = Boolean(
                    now <= new Date(endDate) && now >= new Date(startDate)
                  );

                  if (offerEnable && isofferValid) {
                    product["prodHasOffer"] = true;
                  } else {
                    product["prodHasOffer"] = false;
                  }
                  return (
                    <ListGroup.Item
                      className="subCategory d-flex justify-content-between"
                      key={product.id}
                      gutter={8}
                    >
                      <Col span={2} className="d-flex align-items-center">
                        <img
                          src={`${s3url}/${product.thumbnail}`}
                          style={{
                            height: "55px"
                          }}
                        />
                      </Col>
                      <Col
                        span={5}
                        className="d-flex flex-column justify-content-center align-items-start"
                      >
                        <p>{product.name}</p>
                        {offerEnable && isofferValid ? (
                          <div className="d-flex flex-column">
                            {" "}
                            <div className="d-flex align-items-center">
                              <p>
                                Kr. {offerPrice ? offerPrice : price} /
                                {product.unitType}
                              </p>{" "}
                              <img
                                src={offerIcon}
                                alt="offer available"
                                width={60}
                                height={20}
                                style={{ marginLeft: "10px" }}
                              />
                            </div>{" "}
                            <p
                              style={{
                                textDecorationLine: "line-through"
                              }}
                            >
                              {" "}
                              Kr. {price} /{product.unitType}
                            </p>
                          </div>
                        ) : (
                          <p>
                            Kr. {price} /{product.unitType}
                          </p>
                        )}
                      </Col>
                      <Col
                        span={1}
                        className="d-flex justify-content-end align-items-center"
                      >
                        <button
                          className="addToCart"
                          onClick={() => {
                            addToCart(
                              {
                                posId: new Date().getTime(),
                                id: product._id,
                                productName: product.name,
                                productPrice: product.price,
                                quantity: 1,
                                categoryId: product.categoryId,
                                status: "IN-PROCESS",
                                prodTax: product.prodTax,
                                unitType: product.unitType,
                                availQuantityArr: product.availQuantity,
                                availQuantity: product.availQuantity[0],
                                offerPrice: product.offer.offerPrice,
                                prodHasOffer: product.prodHasOffer
                              },

                              "add"
                            );
                          }}
                        >
                          Add
                        </button>
                      </Col>
                    </ListGroup.Item>
                  );
                })}
              </div>
            </ListGroup>
          </Col>
        )}
        <Col xs lg="4" className="productCol justify-content-between mt-3 pt-3">
          <div className="p-2 cartDisplaySection mx-3">
            {cartData.length > 0 ? (
              <>
                {reversedCart.map((e) => {
                  return (
                    <div className="col-md-24 mb-2 pl-0" key={e._id}>
                      <Card className="d-flex py-0 px-2 align-items-center cartProductCard p-0">
                        <div className="d-flex justify-content-between">
                          <div>
                            <p className="mb-0">
                              <b>{e.productName}</b> &nbsp;&nbsp; *{e.quantity}
                            </p>
                          </div>
                          <p className="mb-0">
                            <b>Kr.{e.totalAmount}</b>
                          </p>
                        </div>

                        <div className="d-flex justify-content-between align-items-center pt-3">
                          {!e.availQuantity ? (
                            ""
                          ) : (
                            <>
                              <p>Select quantity</p>
                              <div>
                                <select
                                  name="availQuantity"
                                  id="availQuantity"
                                  style={{
                                    border: "1px solid",
                                    outline: "0px",
                                    fontSize: "12px",
                                    borderRadius: "10px"
                                  }}
                                  onChange={(event) => dropdownAction(event, e)}
                                >
                                  {e.availQuantityArr.map((qnty) => (
                                    <option
                                      value={qnty}
                                      key={qnty}
                                      multiple={true}
                                      selected={(e.availQuantity == qnty)? 'selected' : '' }
                                    >
                                      {qnty}
                                    </option>
                                  ))}
                                </select>

                                {!e.unitType ? (
                                  ""
                                ) : (
                                  <b>&nbsp;&nbsp;{e.unitType}</b>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="d-flex justify-content-between pt-3">
                          {" "}
                          <a
                            style={{ color: "red" }}
                            className="mb-0 d-flex justify-content-center align-items-center"
                            onClick={() => handleShow(e)}
                          >
                            <u>
                              <i>slette</i>
                            </u>
                          </a>
                          <div className="d-flex w-100 justify-content-end align-items-center my-2">
                            <Button
                              className="minus"
                              onClick={() => {
                                addToCart(e, "minus");
                              }}
                            >
                              -
                            </Button>

                            <p className="mx-3">{e.quantity}</p>
                            <Button
                              className="plus"
                              onClick={() => {
                                addToCart(e, "add");
                              }}
                            >
                              +
                            </Button>
                          </div>
                        </div>
                      </Card>
                      <Modal
                        onCancel={handleClose}
                        visible={confirmShow}
                        closable={true}
                      >
                        <h6>
                          Er du sikker på at du vil slette dette elementet?
                        </h6>
                        <Button onClick={() => deleteData(delProduct)}>
                          Bekrefte
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                          key="back"
                          className="btn btn-secondary"
                          onClick={() => handleClose()}
                        >
                          Avbryt
                        </Button>
                      </Modal>
                    </div>
                  );
                })}
              </>
            ) : (
              <p>Add Product</p>
            )}
          </div>
          <div>
            <div className="p-3 totalBillSummary">
              <Descriptions className="d-flex align-items-center justify-content-between">
                <Descriptions.Item label="Sub-Total">
                  Kr. {Number(subTotal).toFixed(2)}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions className="d-flex align-items-center justify-content-between">
                {mvaArray.map((e) => {
                  let mvaLabel = `MVA(${e.tax})`;
                  return (
                    <Descriptions.Item label={mvaLabel}>
                      Kr. {Number(e.taxTotal).toFixed(2)}
                    </Descriptions.Item>
                  );
                })}
                <Descriptions className="d-flex align-items-center justify-content-between">
                <Descriptions.Item label="DriveOut Charges">
                  Kr. {cityDriveOut}
                </Descriptions.Item>
              </Descriptions>
              </Descriptions>
              <Descriptions className="d-flex align-items-center justify-content-between">
                <Descriptions.Item label="Total">
                  Kr. {totalOfProducts}
                </Descriptions.Item>
              </Descriptions>
            </div>
            <div className="vendorBtn">
              <button
                onClick={() => {
                  clearCartPro();
                }}
                className="mt-2"
              >
                Clear Cart
              </button>
              <button
                onClick={() => {
                  ToProcessOrder();
                }}
                className="mt-2"
              >
                Process Order
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default VendorsProduct;
