import LocationModal from "../../../containers/Modal/LocationModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col} from "antd";

const MapForm = () => {
  let currentLocation = useSelector((state) => state.locationReducer);
  let [showLocationModal, setShowLocationModal] = useState(false);
  const onShowCancelModal = () => {
    setShowLocationModal(false);
  };
  return (
    <div className="px-3">
      <Row>
        <Col span={24} style={{ padding: "10px" }}>
          <h5 className="mb-0">Adresse</h5>
        </Col>
      </Row>
      <Row className="map-row my-3">
        <Col span={24} style={{ padding: "10px" }}>
          <div className="d-flex justify-content-between text-white">
            <a href="#" className="d-flex align-items-center">
              {currentLocation.locationAddress}
            </a>
            <a
              className="change-btn"
              onClick={() => {
                setShowLocationModal(true);
              }}
            >
              Bekrefte
            </a>
          </div>
        </Col>
      </Row>
      {showLocationModal && (
        <LocationModal
          onShowCancelModal={onShowCancelModal}
          isDirection={true}
        ></LocationModal>
      )}
    </div>
  );
};

export default MapForm;
