import { Layout } from "antd";
import "./topbar.css";
import Logo from "../../assets/logo.png";
import { FaShoppingCart } from "react-icons/fa";
import {Badge} from "react-bootstrap";
const { Header } = Layout;

const TopBar = () => (
  <>
    <Layout>
      <Header
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
        }}
      >
        <div className="navWrapper">
          <div className="logo">
            <img src={Logo} style={{ width: "60px" }} />
            <h4 style={{ color: "white" }}>Restaurant Name</h4>
          </div>
          <div className="cartLogo">
            <FaShoppingCart className="cartIcon" />
            <Badge bg="secondary" className="cartBadge">
              2
            </Badge>
          </div>
        </div>
      </Header>
    </Layout>
  </>
);

export default TopBar;
