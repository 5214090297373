import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  Descriptions,
  Tag,
  Divider,
  notification
} from "antd";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import axios from "axios";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
// import "./vendor.css";

const VendorCheckout = (props) => {
  const location = useLocation();
  const data = location.state;
  const cartData = data.cartData;
  let cart = useSelector((state) => state.cartReducer);
  const [form] = Form.useForm();
  let [disabled, setDisabled] = useState(false);
  let [enablePay, setEnablePay] = useState(false);
  let user = useSelector((state) => state.userReducer);
  let customer = useSelector((state) => state.customerReducer);
  let [isformValid, setIsFormValid] = useState(false);
  const onFinish = (values) => {};
  const { Option } = Select;
  let [firstName, setFirstName] = useState("");
  let [lastName, setlastName] = useState("");
  let [phoneNo, setPhoneNo] = useState("");
  let [countryCode, setCountryCode] = useState("47");
  let [email, setEmail] = useState("");
  let [formValid, setFormValid] = useState(false);
  let [btnCardValue, setbtnCardValue] = useState("Pay");
  let [btnTidyPay, setbtnTidyPay] = useState("Pay");
  let [userData, setUserData] = useState({});
  let [customerData, setCustomerData] = useState("");
  let dispatch = useDispatch();
  let retailerid = localStorage.retailerid;
  let vendorOrderType = localStorage.vendorOrderType;
  let tdServiceCharge = localStorage.tdServiceCharge;
  console.log("tdServiceCharge", localStorage);
  const onChangeEvent = (event) => {
    if (event.target.name == "firstName") {
      setFirstName(event.target.value);
    }
    if (event.target.name == "lastName") {
      setlastName(event.target.value);
    }
    if (event.target.name == "email") {
      setEmail(event.target.value);
    }
    if (event.target.name == "phoneNo") {
      if (event.target.value.length <= 10) {
        if (!isNaN(event.target.value)) {
          setPhoneNo(event.target.value);
        }
      }
    }
  };
  //Set initial Form values
  useEffect(() => {
    if (user._id != "") {
      form.setFieldsValue({
        firstname: user.firstName,
        lastname: user.lastName,
        email: user.email,
        phoneNo: user.phone
      });
      setFirstName(user.firstName);
      setlastName(user.lastName);
      setEmail(user.email);
      setPhoneNo(user.phone);
    }
    if (user._id == "" && customer._id != "") {
      form.setFieldsValue({
        firstname: customer.firstName,
        lastname: customer.lastName,
        email: customer.email,
        phoneNo: customer.phone
      });
      setFirstName(customer.firstName);
      setlastName(customer.lastName);
      setEmail(customer.email);
      setPhoneNo(customer.phone);
    }
  }, [user.firstName, user.lastName, user.email, user.phone]);

  useEffect(() => {
    if (props.formSet) {
      setFirstName("");
      setlastName("");
      setEmail("");
    }
  }, [props.formSet]);

  useEffect(() => {
    if (
      lastName.trim() != "" &&
      firstName.trim() != "" &&
      email.includes("@") &&
      phoneNo.toString().trim() != ""
    ) {
    } else {
    }
  }, [lastName, firstName, email, phoneNo, countryCode]);
  const onChangeCountryCode = (event) => {
    setCountryCode(event);
  };
  const onFinishFailed = (errorInfo) => {};

  const onAddCustomer = async () => {
    if (lastName != "" && firstName != "" && email && phoneNo != "") {
      let savePayload = {
        lastName: lastName,
        firstName: firstName,
        email: email,
        countryCode: countryCode,
        phone: phoneNo
      };
      try {
        let response = await axios.post(
          `${apiUrl}${tDriveEndpoint}appuser/appuserweb`,
          savePayload
        );
        if (response.status == 200) {
          savePayload.id = response.data.data;
          setDisabled(true);
        }
      } catch (error) {}
      setCustomerData(savePayload);
    } else if (email == "") {
      notification.open({
        message: "*Fill all the required fields!",
        duration: 2
      });
    }
  };
  let onCreateOrder = async () => {
    let orderPayload = {
      items: cartData,
      customerId: customerData.id,
      branchId: retailerid,
      orderType: vendorOrderType,
      customerType: "tdUser",
      discount: 5,
      customerDetail: customerData,
      checkPayment: true,
      branchType: "retailer",
      latitude: location.latitude,
      longitude: location.longitude
    };
    try {
      let response = await axios.post(
        `${apiUrl}/api/v1/vendor/orders/createVendorOrder`,
        orderPayload
      );
      if (response.status == 200) {
        let orderResponse = response.data;
        let orderResponseUrl = response.data.paymentUrl;
        console.log("orderResponseUrl: ", orderResponseUrl);
        window.location.replace(orderResponseUrl);
      }
    } catch (error) {}
  };

  let fullTotal = data.total + Number(tdServiceCharge);
  console.log("fullTotal: ", data.total + Number(tdServiceCharge));

  return (
    <div className="formWrapper vendorCheckout" id="checkout">
      <Row className="checkoutRow justify-content-evenly">
        <Col xs={24} sm={12} className="site-card-border-less-wrapper p-0">
          <Card bordered={false} className="cardContainer">
            <Row className="justify-content-between formHeader">
              <Col className="d-flex align-items-center">Kundedetaljer</Col>
              <Col className="formHeader">
                <Tag color="#f50">{vendorOrderType}</Tag>
              </Col>
            </Row>
            <Form
              form={form}
              className="mainForm checkoutForm p-3"
              name="basic"
              labelCol={{
                span: 8
              }}
              wrapperCol={{
                span: 12
              }}
              initialValues={{
                remember: true
              }}
              autoComplete="off"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Row>
                <Col xs={24} md={24}>
                  <Form.Item
                    label="Fornavn"
                    name="firstname"
                    className="formField"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Fornavn!"
                      }
                    ]}
                  >
                    <Input
                      value={firstName}
                      onChange={onChangeEvent}
                      name="firstName"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item
                    label="Etternavn"
                    name="lastname"
                    className="formField"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Etternavn!"
                      }
                    ]}
                  >
                    <Input
                      name="lastName"
                      value={lastName}
                      onChange={onChangeEvent}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={24}>
                  <Form.Item
                    name="email"
                    label="E-Mail"
                    className="formField"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Please input your E-Mail!"
                      }
                    ]}
                  >
                    <Input
                      name="email"
                      value={email}
                      onChange={onChangeEvent}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item
                    label="Telefonnr"
                    name="phoneNo"
                    className="formField"
                  >
                    <Input.Group compact>
                      <Select
                        style={{ width: "30%" }}
                        placeholder="+47"
                        onChange={onChangeCountryCode}
                      >
                        <Option value="47">+47</Option>
                        <Option value="91">+91</Option>
                      </Select>
                      <Input
                        min="0"
                        style={{ width: "70%" }}
                        name="phoneNo"
                        type="number"
                        value={phoneNo}
                        onChange={onChangeEvent}
                      />
                    </Input.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row className="formFooter saveContainer">
              <Button
                type="primary"
                className="saveBtn w-100"
                onClick={onAddCustomer}
                disabled={disabled}
              >
                Lagre
              </Button>
            </Row>
          </Card>
        </Col>
        <Col
          xs={24}
          sm={9}
          className="productTotalContainer site-card-border-less-wrapper"
        >
          <Card bordered={false} className="cardSize">
            <Row className="formHeader">
              <Col>Innbetaling</Col>
            </Row>
            <div className="p-3">
              <div className="d-flex flex-column" id="finalCart">
                {cartData.map((e) => {
                  return (
                    <div
                      className="col-md-24 mb-2 pl-0 payment-card"
                      key={e.productId}
                    >
                      <Row className="pt-3">
                        <Col span={18}>
                          <p className="itemName">
                            {e.productName} &nbsp;&nbsp; *{e.quantity}
                          </p>
                        </Col>
                        <Col span={6} className="d-flex justify-content-end">
                          <p className="itemName">
                            Kr.{e.totalAmount.toFixed(2)}
                          </p>
                        </Col>

                        <Col span={24} className="d-flex justify-content-end">
                          Quantity: {e.quantity}pkt x {e.availQuantity}{" "}
                          {e.unitType}
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </div>
            </div>
            <Divider />
            <div className="totalBillSummary">
              <Descriptions className="d-flex align-items-center justify-content-between">
                <Descriptions.Item label="Sub Total">
                  Kr.
                  {data.orderTotal}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions className="d-flex align-items-center justify-content-between">
                {data.mvaArray.map((e) => {
                  let mvaLabel = `MVA(${e.tax})`;
                  return (
                    <Descriptions.Item label={mvaLabel}>
                      Kr. {Number(e.taxTotal).toFixed(2)}
                    </Descriptions.Item>
                  );
                })}
              </Descriptions>
              <Descriptions className="d-flex align-items-center justify-content-between">
                <Descriptions.Item label="Service Charges">
                  Kr. {tdServiceCharge}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions className="d-flex align-items-center justify-content-between">
                <Descriptions.Item label="Total">
                  Kr. {fullTotal}
                </Descriptions.Item>
              </Descriptions>
            </div>{" "}
            <Divider />
            <Col className="payCard mt-3 p-0">
              <div className="d-flex">
                <Button
                  type="primary"
                  className="w-100 saveBtn m-0"
                  block
                  disabled={!disabled}
                  onClick={() => onCreateOrder(cart, "Dintero")}
                >
                  {btnCardValue}
                </Button>
              </div>
            </Col>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default VendorCheckout;
