import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";

import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";

import BottomFooter from "../Footer/BottomFooter";
import { NavLink } from "react-router-dom";

import {Col,Form,FloatingLabel,Button,Modal,Alert} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import MobileNavBottom from "../Footer/MobileNavBarBottom";

import { useDispatch, useSelector } from "react-redux";
import PrivacyHeader from "../NavbarHeader/PrivacyHeader";

export default function Reset() {
  const dispatch = useDispatch();

  let user = useSelector((state) => state.userReducer);

  let [alertMsg, setAlertMsg] = useState("");
  let [alertType, setAlertType] = useState("");
  let [showAlert, setShowAlert] = useState(false);

  let [statePhone, setStatePhone] = useState("");

  const [modalShow, setModalShow] = useState(false);

  const validateForm = (valid_inputs) => {
    for (let i = 0; i < valid_inputs.length; i++) {
      if (valid_inputs[i] == "") {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let phoneNumber = e.target.inputPhone.value;
    let countryCode = e.target.inputCountryCode.value;

    setStatePhone(phoneNumber);

    console.log("---phone, countrycode---", phoneNumber, countryCode);

    let inputFields = [phoneNumber, countryCode];

    if (!validateForm(inputFields)) {
      console.log(phoneNumber);
      setShowAlert(true);
      setAlertType("danger");
      setAlertMsg("Please fill all the required fields!");
    } else {
      if (phoneNumber.length > 10 || phoneNumber.length < 8) {
        setShowAlert(true);
        setAlertType("danger");
        setAlertMsg("Please enter a valid Phone number!");
        return;
      }

      console.log("---posting data----");

      setShowAlert(true);
      setAlertType("warning");
      setAlertMsg("Vennligst vent...");

      //try{

      let formData = {
        countryCode: countryCode,
        phone: phoneNumber
      };

      await axios
        .post(`${apiUrl}${tDriveEndpoint}appuser/appforgetpassword`, formData)
        .then((response) => {
          console.log("---response server---", response);

          if (response.data.status == 200) {
            console.log("----ok----");

            setShowAlert(true);
            setAlertType("success");
            // setAlertMsg('Account created, please wait...');
            setAlertMsg(
              "OTP sendes til ditt registrerte telefonnummer for gjenoppretting av passord."
            );

            setModalShow(true);
          } else {
            console.log("----error----");

            setShowAlert(true);
            setAlertType("danger");
            setAlertMsg(response.data.message);
          }
        })
        .catch((error) => {
          console.log("---error user---", error.message);
          setShowAlert(true);
          setAlertType("danger");
          setAlertMsg(
            "Feil: Ugyldig informasjon angitt, vennligst sjekk informasjonen din f�r du sender inn skjemaet."
          );
        });
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    let newPassword = e.target.inputPassword.value;
    let otp = e.target.inputOTP.value;

    console.log("---phone, countrycode---", statePhone, newPassword, otp);

    let inputFields = [newPassword, otp];

    if (!validateForm(inputFields)) {
      setShowAlert(true);
      setAlertType("danger");
      setAlertMsg("Please fill all the required fields!");
    } else {
      console.log("---posting data----");

      setShowAlert(true);
      setAlertType("warning");
      setAlertMsg("Vennligst vent...");

      let formData = {
        phone: statePhone,
        otp: otp,
        newPassword: newPassword
      };

      await axios
        .post(
          `${apiUrl}${tDriveEndpoint}appuser/appresetpasswordwithotp`,
          formData
        )
        .then((response) => {
          console.log("---response server---", response);

          if (response.data.status == 200) {
            console.log("----ok----");

            setShowAlert(true);
            setAlertType("success");
            setAlertMsg("Password reset successfully.");

            navigate("/login");
          } else {
            console.log("----error----");

            setShowAlert(true);
            setAlertType("danger");
            setAlertMsg(response.data.message);
          }
        })
        .catch((error) => {
          console.log("---error user---", error.message);
          setShowAlert(true);
          setAlertType("danger");
          setAlertMsg(
            "Feil: Ugyldig informasjon angitt, vennligst sjekk informasjonen din f�r du sender inn skjemaet."
          );
        });
    }
  };

  const navigate = useNavigate("");

  useEffect(() => {
    if (user.token) {
      navigate("/profile");
    }
  }, []);

  //Modal Component
  const ResetPasswordModal = (props) => {
    return (
      <Modal
        {...props}
        animation={false}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-dark">
            Skriv inn OTP
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={handleResetPassword}>
            <Form.Group className="row mb-3" controlId="formBasicPassword">
              <Col md>
                <FloatingLabel
                  controlId="floatingInputGridd"
                  label="Nytt passord*"
                >
                  <Form.Control required type="password" name="inputPassword" />
                </FloatingLabel>
              </Col>

              <Col md>
                <FloatingLabel controlId="floatingInputGriid" label="OTP*">
                  <Form.Control required type="text" name="inputOTP" />
                </FloatingLabel>
              </Col>
            </Form.Group>

            {showAlert && <Alert variant={alertType}>{alertMsg}</Alert>}
            <div className="d-flex justify-content-between">
              <Button variant="primary" type="submit">
                Skriv inn OTP
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };
  //////

  return (
    <>
      {!isMobile && <PrivacyHeader />}
      {!isMobile && (
        <section className="mission profilePages  p-5">
          <div className="d-flex justify-content-center py-5 mt-5 ">
            <Col xs lg="6" className="pb-3 logBox">
              {" "}
              <h2 className="display-5 ">Glemt passord?</h2>
              <p className="my-2">
                <i>Vil du tilbakestille passordet ditt?</i>
              </p>
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="row mb-3" controlId="formBasicEmail">
                  <Col>
                    <Form.Select
                      aria-label="Velg landskode"
                      name="inputCountryCode"
                    >
                      <option value="47">+47</option>
                      <option value="91">+91</option>
                    </Form.Select>
                  </Col>

                  <Col xs={9}>
                    <Form.Control
                      required
                      type="number"
                      placeholder="Telefonnummer"
                      name="inputPhone"
                    />
                  </Col>
                </Form.Group>
                {showAlert && <Alert variant={alertType}>{alertMsg}</Alert>}{" "}
                <Button variant="primary" type="submit">
                  Send OTP
                </Button>{" "}
                <Col span={24} className="d-flex justify-content-between">
                  <p className="py-2">
                    <u>
                      <NavLink to="/login" className=" col-1">
                        Logg Inn
                      </NavLink>
                    </u>
                  </p>
                  <p className="py-2">
                    Ny bruker?{" "}
                    <NavLink to="/signup" className=" col-1">
                      <i>
                        <u> "Registrer" </u>
                      </i>
                    </NavLink>
                  </p>
                </Col>{" "}
              </Form>
            </Col>
          </div>
        </section>
      )}

      {isMobile && (
        <section className="mission p-5">
          <div className="d-flex justify-content-center">
            <Col xs lg="6" className="pb-3 logBox">
              {" "}
              <h2 className="display-5 ">Glemt passord?</h2>
              <p className="my-2">
                <i>Vil du tilbakestille passordet ditt?</i>
              </p>
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="row mb-3" controlId="formBasicEmail">
                  <Col className="p-0">
                    <Form.Select
                      aria-label="Velg landskode"
                      name="inputCountryCode"
                    >
                      <option value="47">+47</option>
                      <option value="91">+91</option>
                    </Form.Select>
                  </Col>

                  <Col xs={9}>
                    <Form.Control
                      required
                      type="number"
                      placeholder="Telefonnummer"
                      name="inputPhone"
                    />
                  </Col>
                </Form.Group>
                {showAlert && <Alert variant={alertType}>{alertMsg}</Alert>}{" "}
                <Button variant="primary" type="submit">
                  Send OTP
                </Button>{" "}
                <Col span={24} className="d-flex justify-content-between">
                  <p className="py-2">
                    <u>
                      <NavLink to="/login" className=" col-1">
                        Logg Inn
                      </NavLink>
                    </u>
                  </p>
                  <p className="py-2">
                    Ny bruker?{" "}
                    <NavLink to="/signup" className=" col-1">
                      <i>
                        <u> "Registrer" </u>
                      </i>
                    </NavLink>
                  </p>
                </Col>{" "}
              </Form>
            </Col>
          </div>
        </section>
      )}
      <ResetPasswordModal show={modalShow} onHide={() => setModalShow(false)} />

      {!isMobile && <BottomFooter />}

      {isMobile && <MobileNavBottom />}
    </>
  );
}
