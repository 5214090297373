import React from "react";
import BottomFooter from "../Footer/BottomFooter";
import PrivacyHeader from "../NavbarHeader/PrivacyHeader";
import { isMobile } from "react-device-detect";

export const CouponPolicy = () => {
  return (
    <>
      {!isMobile && <PrivacyHeader />}
      <section className="mission p-5">
        <div className="container">
          <div className="row privacy-header text-center d-flex justify-content-center">
            <h2 className="display-5 mb-4 ">KUPONGPOLITIKK</h2>
          </div>
        </div>
        <div className="container nav_bg">
          <div className="row privacyText">
            <div className="col-10 mx-auto privacyText">
              <div className="row privacyText">
                <div className="row py-4 privacyText">
                  <span>BETINGELSER OG VILKÅR:</span>
                </div>
                <div className="row privacyText">
                  <p>
                    En bruker kan bruke 100 % av kupongen én eller flere ganger.
                    <br />
                    Kupongen er gyldig for både appbrukere og nettbrukere.
                  </p>
                </div>
                <div className="row privacyText pt-3">
                  <p>En bruker kan få kupong på to måter: </p>
                  <p>
                    <ol>
                      <li>
                        Direkte fra <strong>OM RESTAURANT CLOUD admin</strong>{" "}
                        eller <strong>EzyGo nett- og app portaler</strong> .
                      </li>
                      <li>
                        Av seg selv på <strong>EzyGo-plattformen</strong>{" "}
                        <a href="https://ezygo.shop" className="privacyLink">
                          ezygo.shop
                        </a>{" "}
                        eller app.
                      </li>
                    </ol>
                  </p>
                </div>
                <div className="row privacyText pt-3">
                  <p>
                    <strong>OM RESTAURANT CLOUD</strong> eller{" "}
                    <strong>EzyGo</strong> forbeholder seg retten til å
                    kansellere/endre/modifisere/legge til/slette noen av
                    vilkårene og betingelsene for kupongen når som helst uten
                    varsel.
                  </p>
                </div>
                <div className="row privacyText pt-3">
                  <p>
                    <strong>OM RESTAURANT CLOUD</strong> eller{" "}
                    <strong>EzyGo</strong> forbeholder seg retten til å si opp
                    kupongen når som helst uten varsel.
                  </p>
                </div>
                <div className="row privacyText pt-3">
                  <p>
                    <strong>OM RESTAURANT CLOUD</strong> eller{" "}
                    <strong>EzyGo</strong> forbeholder seg retten til å nekte å
                    honorere kupongen på grunn av mistanke eller misbruk av
                    kupongen av enhver kunde uten å gi kunden noen forklaring på
                    dette.
                  </p>
                </div>
                <div className="row privacyText pt-3">
                  <p>
                    Under ingen omstendigheter skal{" "}
                    <strong>OM RESTAURANT CLOUD</strong> eller{" "}
                    <strong>EzyGo</strong> være ansvarlig for misbruk eller
                    misbruk av kupongen på grunn av uaktsomhet fra kunden.
                  </p>
                </div>
                <div className="row privacyText pt-3">
                  <p>Kuponger kan klubbes med andre betalingsalternativer.</p>
                  <p>
                    En bruker kan reservere et bord på restauranten ved å bruke
                    en kupong som kan løses inn på faktureringstidspunktet.
                    Ingen refusjon/innløsning av kuponger tilbys gjennom
                    nettstedet eller appen for kontanter.
                  </p>
                </div>
                <div className="row privacyText pt-3">
                  <p>
                    På tidspunktet for utløpet av kupongen, hvis et balansebeløp
                    er til stede i kupongen, vil det gå direkte til{" "}
                    <a
                      href="https://treeforlife.world/"
                      className="privacyLink"
                    >
                      TFL (TREE FOR LIFE)
                    </a>{" "}
                    en miljøbasert NGO.
                  </p>
                </div>
                <div className="row privacyText pt-3">
                  <p>
                    KUPONGLØBET ER GYLDIG FOR KUPONGSALG ELLER ANDRE PRODUKTER
                    SOM SELGES DIREKTE AV OM RESTAURANT CLOUD RESTAURANTER eller
                    EzyGo WEB & APP.
                  </p>
                </div>
                <div className="row privacyText pt-3">
                  <p>
                    <strong>UTFØRER:</strong> EzyGo eller OM RESTAURANT CLOUD
                  </p>
                  <p>
                    <strong>PLATTFORM:</strong> OM RESTAURANT CLOUD
                  </p>
                  <p>
                    <strong>PARTNER: </strong>
                    <a href="https://ezygo.no/" className="privacyLink">
                    EzyGo
                    </a>{" "}
                    er et teknologileverandørselskap hvor du enkelt kan legge
                    inn bestillinger og benytte andre tjenester også.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BottomFooter />
    </>
  );
};
