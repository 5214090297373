function getDiscount(price, branch, orderType) {
  let discount = 0;
  if (orderType == "Drive Out") {
    let discountRange = branch.tdDriveOutDiscountRange
      ? branch.tdDriveOutDiscountRange
      : 0;

    if (price >= discountRange.range1Min && price <= discountRange.range1Max) {
      discount = +discountRange.discount1;
    } else if (
      price >= discountRange.range2Min &&
      price <= discountRange.range2Max
    ) {
      discount = +discountRange.discount2;
    } else if (
      price >= discountRange.range3Min &&
      price <= discountRange.range3Max
    ) {
      discount = +discountRange.discount3;
    } else {
      discount = 0;
    }
  } else {
    let discountRange = branch.tdTakeAwayDiscountRange
      ? branch.tdTakeAwayDiscountRange
      : 0;

    if (price >= discountRange.range1Min && price <= discountRange.range1Max) {
      discount = +discountRange.discount1;
    } else if (
      price >= discountRange.range2Min &&
      price <= discountRange.range2Max
    ) {
      discount = +discountRange.discount2;
    } else if (
      price >= discountRange.range3Min &&
      price <= discountRange.range3Max
    ) {
      discount = +discountRange.discount3;
    } else if (
      price >= discountRange.range4Min &&
      price <= discountRange.range4Max
    ) {
      discount = +discountRange.discount4;
    } else {
      discount = 0;
    }
  }
  let discountPrice = (+price * discount) / 100;
  return { discountCalculated: discount, discountPrice };
}

export default getDiscount;
