import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Collapse, Carousel } from "antd";

import moment from "moment";
import { s3url } from "../../../helper/endpoint";
import SuperCategoryComponent from "./SuperCategory/SuperCategory";
import ProductCategoryComponent from "./SuperCategory/ProductCategory/ProductCategory";
import ProductComponent from "./SuperCategory/ProductCategory/Product/ProductComponent";
import { getSuperCategory } from "../../../store/actions/productaction";
import { useDispatch, useSelector } from "react-redux";
import Productmodal from "./SuperCategory/ProductCategory/Product/Modals/ConfirmProductModal";
import axios from "axios";
import { apiUrl } from "../../../helper/endpoint";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import MobileNavBottom from "../../Footer/MobileNavBarBottom";
import {
  getIncreasedPrices,
  getProductsIngrident
} from "./Helper/producthelper";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent
} from "@mui/material";

import { rootAction } from "../../../store/slices/rootSlices";
import { restaurantAction } from "../../../store/slices/restaurantslices";
import { customerAction } from "../../../store/slices/customerslices";
import { cartAction } from "../../../store/slices/cartslices";

const { Panel } = Collapse;

export const Product = () => {
  const slug = useParams();
  let [isProductModalVb, setIsProductModalVisible] = useState(false);
  let [subCategoryState, setSubCategoryState] = useState([]);
  let [productState, setProductState] = useState([]);
  let [singleProduct, setSingleProductState] = useState();
  let [singleCat, setsingleCatState] = useState();
  let [allIng, setallIngState] = useState([]);
  let [onOpenProductMobileModal, setOnOpenMobileProductModal] = useState(false);
  let [productModal, setProductModal] = useState(false);
  const [displayPopUp, setDisplayPopUp] = useState(true);
  const [open, setOpen] = useState(true);

  let dispatch = useDispatch();
  let superCategory = useSelector(
    (state) => state.productReducer.superCategory.data
  );
  let productAdv = useSelector(
    (state) => state.productReducer.superCategory.advertisements
  );
  let res = useSelector((state) => state.restaurantReducer.selectedRestaurant);
  let root = useSelector((state) => state.rootReducer);

  useEffect(() => {
    if (slug && Object.keys(slug).length > 0) {
      //set orderType take away/ drive out
      if (!res) {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        // console.log("not working");
      }
      if (root.orderType == "") {
        dispatch(rootAction.setOrderType("Take Away"));
      }
      getRestaurant();
    }
  }, []);

  useEffect(() => {
    if (slug && Object.keys(slug).length > 0) {
      dispatch(getSuperCategory(slug.slug, "slug"));
    }
    if (res && Object.keys(slug).length === 0) {
      dispatch(getSuperCategory(res._id, "id"));
    }
  }, []);

  useEffect(() => {
    dispatch(customerAction.removeCustomer());
  }, []);

  useEffect(() => {
    if (superCategory && superCategory.length) {
      setSubCategoryState(superCategory);
      let singleProd = superCategory[0].products;
      setProductState(
        superCategory && superCategory.length > 0 ? singleProd : []
      );
      setsingleCatState(
        superCategory && superCategory.length > 0 ? superCategory[0].name : ""
      );
      setSingleProductState(singleProd[0]);
      setallIngState(superCategory[0].ing);
    }
  }, [superCategory]);

  const getRestaurant = async () => {
    let response = await axios.post(
      `${apiUrl}/api/v1/treeDrive/restaurant/restaurantdistancetakeaway`,
      {
        latitude: "",
        longitude: "",
        slug: slug.slug
      }
    );

    if (response.data.status !== 200) {
      dispatch(restaurantAction.removeSelectedRestaurant());
    } else {
      dispatch(restaurantAction.saveRestuarant(response.data.data.restaurants));
      dispatch(
        restaurantAction.saveSelectedRestaurant(
          response.data.data.restaurants[0]
        )
      );

      if (res._id != response.data.data.restaurants[0]._id) {
        dispatch(cartAction.emptyCart());
      }
    }
  };

  let onClickSuperCategoryHandler = (sinRes) => {
    let products = sinRes.products.length > 0 ? sinRes.products : [];
    let singleProduct = products.length > 0 ? products[0] : null;
    setsingleCatState(sinRes.name);
    setProductState(products);
    setSingleProductState(singleProduct);
    setallIngState(sinRes.ing);
  };

  const onClickProducts = (sinProd) => {
    if (isMobile) {
      setOnOpenMobileProductModal(true);
    }
    setSingleProductState(sinProd);
    setProductModal(true);
    setIsProductModalVisible(false);
  };

  const onCloseMobileProductModal = () => {
    setOnOpenMobileProductModal(false);
  };

  // const handleCancelModal = () => {
  //   setProductModal(false);
  // };

  const closePopUp = () => {
    setDisplayPopUp(false);
  };

  const getFinalPrice = (product, priceInc) => {
    let productPrice = {};

    let increasedPrice = getIncreasedPrices(product, priceInc);

    productPrice.finalPrice = increasedPrice;
    productPrice.showOffer = false;

    if (product.offerEnable) {
      let todayDate = new Date();
      let startDate = new Date(product.offer.startDate);
      let endDate = new Date(product.offer.endDate);

      if (todayDate >= startDate && todayDate <= endDate) {
        let prod = {};
        prod.price = product.offer.offerPrice;

        let offerPriceIncreased = getIncreasedPrices(prod, priceInc);

        productPrice.finalPrice = offerPriceIncreased;
        productPrice.showOffer = true;
      }
    }
    return productPrice;
  };

  // for advertisement
  const [evt1, setEvt1] = useState([]);

  useEffect(() => {
    let accumulatedData = [];
    if (window.innerWidth <= 760) {
      if (Array.isArray(productAdv)) {
        productAdv.forEach((ads) => {
          if (ads.type === "picture" && Array.isArray(ads.data)) {
            const imgArr = ads.data;
            const updatedEvents = imgArr.map((event) => ({
              ...event,
              start: moment(event.start).toDate(),
              end: moment(event.end).toDate()
            }));
            accumulatedData = [...accumulatedData, ...updatedEvents];
          }
        });
      }
      setEvt1(accumulatedData);
    }
  }, [productAdv]);

  return (
    <>
      {displayPopUp && (
        <>
          {evt1.length > 0 ? (
            <Dialog open={open} className="onloadModal">
              <IconButton
                aria-label="close"
                onClick={closePopUp}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500]
                }}
              ></IconButton>
              <DialogContent style={{ width: "80vw" }}>
                <Carousel effect="fade" autoplay>
                  {evt1.map((data, index) => (
                    <div key={index}>
                      <img
                        className="w-100"
                        src={`${s3url}/${data.url}`}
                        alt={`Image ${index}`}
                      />
                    </div>
                  ))}
                </Carousel>
              </DialogContent>
              <DialogActions>
                <Button className="popModalBtn" autoFocus onClick={closePopUp}>
                  Greit
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            ""
          )}
        </>
      )}
      <Container className="productWrapper">
        <Row className="main px-4 pt-3">
          <Col xs lg="3" className="col1 p-3">
            <SuperCategoryComponent
              subCategory={
                subCategoryState && subCategoryState.length > 0
                  ? subCategoryState
                  : []
              }
              onClickSuperCategoryHandler={onClickSuperCategoryHandler}
            />
          </Col>
          {!isMobile && (
            <Col xs lg="3" className="subCat p-3">
              <ProductCategoryComponent
                products={productState}
                sinCat={singleCat}
                onClickProducts={onClickProducts}
              />
            </Col>
          )}
          {/*-------mobile collapse code start-------*/}

          {isMobile && (
            <div
              style={{
                paddingBottom: "70px",
                height: "90vh",
                overflow: "hidden",
                overflowY: "scroll"
              }}
            >
              <Col className="CollapseCategoryMobile">
                {subCategoryState && subCategoryState.length > 0
                  ? subCategoryState.map((subCategory, index) => {
                      return (
                        <>
                          <Collapse
                            accordion
                            bordered={false}
                            defaultActiveKey={["1"]}
                            className="site-collapse-custom-collapse"
                          >
                            <Panel
                              header={subCategory.name}
                              key={index + 1}
                              className="site-collapse-custom-panel"
                            >
                              <Col xs lg="3" className="subCat p-3">
                                <ProductCategoryComponent
                                  products={subCategory.products}
                                  sinCat={singleCat}
                                  onClickProducts={onClickProducts}
                                />
                              </Col>
                            </Panel>
                          </Collapse>
                        </>
                      );
                    })
                  : ""}
              </Col>
            </div>
          )}

          {/*-------mobile collapse code end-------*/}

          {!isMobile && res && (
            <Col xs lg="6" className="productCol mt-3">
              <ProductComponent
                singleProduct={singleProduct}
                sinIng={allIng}
                incPricePer={
                  res.tdExtraPriceIncrease ? res.tdExtraPriceIncrease : 0
                }
              />
            </Col>
          )}

          {isMobile && onOpenProductMobileModal && (
            <Productmodal
              closeModal={onCloseMobileProductModal}
              isMobile={isMobile}
              ing={
                getProductsIngrident(allIng, singleProduct)
                  ? getProductsIngrident(allIng, singleProduct)
                  : []
              }
              onCloseMobileProductModal={onCloseMobileProductModal}
              singleProduct={{
                ...singleProduct,
                incPrice: getFinalPrice(
                  singleProduct,
                  res.tdExtraPriceIncrease ? res.tdExtraPriceIncrease : 0
                ).finalPrice,
                showOffer: getFinalPrice(
                  singleProduct,
                  res.tdExtraPriceIncrease ? res.tdExtraPriceIncrease : 0
                ).showOffer
                // incPrice: getFinalPrice(singleProduct,
                //   res.tdExtraPriceIncrease ? res.tdExtraPriceIncrease : 0
                // )
                // incPrice: getIncreasedPrices(
                //   singleProduct,
                //   res.tdExtraPriceIncrease ? res.tdExtraPriceIncrease : 0
                // )
              }}
            />
          )}
        </Row>
      </Container>
      {isMobile && <MobileNavBottom />}
    </>
  );
};
